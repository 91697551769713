import {
  createContext,
  Dispatch,
  ReactNode,
  useContext,

} from "react";
import { FieldOpt, QlikApp } from "../types/qlik-types";
import useContextApp from "./UseContext";

// In QlikApp
interface IQlikAppContext {
  app: QlikApp;
  qlikAppLoading: boolean;
  qlikAppError: boolean | undefined;
  username: string;
  updateGraph: number;
  setUpdateGraph: () => void;
  token: string;
}

const QlikAppContext = createContext({
  app: {} as QlikApp,
  qlikAppLoading: true,
  qlikAppError: undefined
} as IQlikAppContext);

type QlikAppContextProviderProps = {
  children: ReactNode | ReactNode[];
};

const QlikAppContextProvider = ({ children }: QlikAppContextProviderProps) => {
  const {setUpdateGraph, state, loading, qlikAppError, app} = useContextApp();
  return (
    <QlikAppContext.Provider
      value={{
        app,
        qlikAppLoading: loading,
        qlikAppError,
        ...state,
        setUpdateGraph
      }}
    >
      {children}
    </QlikAppContext.Provider>
  );
};

function useQlikAppContext() {
  return useContext(QlikAppContext);
}

export { QlikAppContext, QlikAppContextProvider, useQlikAppContext };
