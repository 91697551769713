import { createStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useNavBarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: (navCollapsed) => (navCollapsed ? "row" : "column"),
      justifyContent: (navCollapsed) => (navCollapsed ? "center" : ""),
      minWidth: (navCollapsed) => (navCollapsed ? "5rem" : "19rem"),
      width: (navCollapsed) => (navCollapsed ? "5rem" : "19rem"),
      height: "92vh",
      overflow: "auto",
      backgroundColor: "#fff",
      position: "sticky",
      top: 0,
      transition: "ease-in-out 0.3s",
      cursor: "pointer",
      [theme.breakpoints.down("md")]: {
        width: "100% !important",
        minWidth: "100% !important",
        height: "auto",
        justifyContent: (navCollapsed) =>
          navCollapsed ? "flex-start !important" : ""
      }
    },
    buttonResetSection: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start'
    },
    sideBarTitle: {
      padding: "1.5rem",
      display: "flex",
      flexDirection: "row"
    },
    fullSideBar: {
      width: (navCollapsed) => (navCollapsed ? "0" : "auto"),
      overflow: "hidden"
    },
    titleIcon: {
      marginLeft: (navCollapsed) => (navCollapsed ? "0" : "auto"),
      marginTop: (navCollapsed) => (navCollapsed ? "1.5rem" : 0),
      cursor: "pointer",
      [theme.breakpoints.down("md")]: {
        marginBottom: (navCollapsed) => (navCollapsed ? "1rem" : 0),
        marginTop: (navCollapsed) => (navCollapsed ? "1rem" : 0)
      }
    }
  })
);
