import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function formatNumberWithSpaces(input: number | string): string {
  // Convert the input to a string if it's not already
  let inputStr = input.toString();

  // Split the integer part from the decimal part
  let parts = inputStr.split('.');
  let integerPart = parts[0];
  let decimalPart = parts.length > 1 ? '.' + parts[1] : '';

  // Reverse the integer part to simplify the insertion of spaces
  let reversedInt = integerPart.split('').reverse().join('');

  // Insert spaces every three characters
  let withSpaces = reversedInt.replace(/(\d{3})(?=\d)/g, '$1 ');

  // Reverse the string back to its original order and append the decimal part, if any
  let formattedNumber = withSpaces.split('').reverse().join('') + decimalPart;

  return formattedNumber;
}

export function camelCaseToCapitalizedText(camelStr: string) {
  const normalized = camelStr.replace(/((?<!^)[A-Z](?![A-Z]))(?=\S)/g, " $1");
  return normalized.charAt(0).toUpperCase() + normalized.slice(1).toLowerCase();
}

export function convertToNumAbbr(value: number, isInteger?: boolean) {
  if (value < 999) return isInteger ? value.toFixed(0) : value.toFixed(2);
  if (value > 999 && value <= 999999) return `${(value / 1000).toFixed(2)} k`;
  if (value > 999999) return `${(value / 1000000).toFixed(2)} M`;
}

export const roundToOneDecimal = (input: number): number => {
  // Check if the input has more than one decimal place
  if (input * 10 % 1 !== 0) {
    return Math.round(input * 10) / 10;
  } else {
    return input;
  }
}

export function calcDifference(compare: number, current: number, oneDecimal: boolean | undefined) {
  const diff = ((current - compare) / compare) * 100;
  return Number.isNaN(diff) ? "-%" : `${oneDecimal ? roundToOneDecimal(diff) : diff.toFixed(2)}%`;
}

export const eliminateNaN = (num: number) => {
  return isNaN(num) ? 0 : num;
};

export const absoluteRegulate = (value: string) => {
  if (!isNaN(value as any)) return Math.round(parseFloat(value)).toString();
  else return value;
};


export const checkCookieWithPrefix = (prefix: string): boolean => {
  const allCookies = Cookies.get();
  console.log(allCookies);
  return Object.keys(allCookies).some((cookieName) => cookieName.startsWith(prefix));
};

export const formatNumber = (num: number, oneDecimal?: boolean): string => {
  // Round the number to the nearest integer
  let roundedNum = Math.round(num);
  if (oneDecimal) roundedNum = roundToOneDecimal(num);
  
  // Convert the rounded number to a string
  //const numStr = roundedNum.toString();

  // Add spaces as thousands separators
  let formattedStr = formatNumberWithSpaces(roundedNum);

  return formattedStr;
};

export const downloadFileWithProgress = async (url: string) => {
  const response : any = await fetch(url);
  const totalSize = parseInt(response.headers.get("Content-Length"), 10);
  const reader = response.body.getReader();

  let downloadedSize = 0;
  const chunks = [];

  while (true) {
    const { done, value } = await reader.read();
    if (done) break;

    chunks.push(value);
    downloadedSize += value.length;
    const progress = (downloadedSize / totalSize) * 100;

    console.log(`Download progress: ${progress.toFixed(2)}%`);
  }

  const file = new Blob(chunks, { type: response.headers.get("Content-Type") });
  return file;
};

export const usePreviousLocation = () =>  {
  const location = useLocation();
  const [previousLocation, setPreviousLocation] = useState<any>({pathname: "/"});

  useEffect(() => {
    setPreviousLocation(location);
  }, [location]);

  return previousLocation;
}


export const shouldUpdate = (currentLocation: string, previousLocation: string) : boolean => {
  const locations = ["/visitAndRegistration", "/sickLeaves", "/expense", "/workAbilityRisk", "/kpi"];
  
  if (currentLocation === "/workAbilityRisk") {
    return true; // Case 1: Current location is "/workAbilityRisk"
  }
  
  if (previousLocation === "/workAbilityRisk") {
    return true; // Case 2: Previous location is "/workAbilityRisk"
  }
  
  if (currentLocation === "/visitAndRegistration" && previousLocation === "/workAbilityRisk") {
    return true; // Case 3: Current location is "/visitAndRegistration" and previous location is "/workAbilityRisk"
  }
  if (currentLocation === "/visitAndRegistration" && previousLocation === "/visitAndRegistration") {
    return true;
  }
  
  return false; // Return false for all other cases
};

export const getToken = (searchurl: string) => {
  console.log("searchurl");
  console.log(searchurl);
  const urlParams = new URLSearchParams(searchurl);
  console.log("urlParams");
  console.log(searchurl);
  const urlParamsX = new URL(searchurl);
  console.log("urlParamsX");
  console.log(urlParamsX);
  const returnValue = urlParams.get("token") ?? urlParamsX.searchParams.get("token");
  console.log("returnValue");
  console.log(returnValue);
  return returnValue ?? null;
}


