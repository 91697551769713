import { useEffect, useState } from "react";

const useWindowSize = () => {
  const [windowWidth, setWidth] = useState<number>(1920);
  const [windowHeight, setHeight] = useState<number>(1080);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return {
    windowWidth,
    windowHeight
  };
};

export default useWindowSize;
