import { Chart } from "../types/componentTypes/Chart";
import { KPIMeter } from "../types/componentTypes/KPIMeter";

export const visitAndRegistrationPageKPIMeters: KPIMeter[] = [
  {
    title: "Käynnit ja kirjaukset kpl",
    kpiId: "QAP_Käynnit_KPI_1",
    comparingKpiId: "QAP_Käynnit_KPI_1_EDV",
    kpiValue: 1,
    isInteger: true
  },
  {
    title: "Käynnit ja kirjaukset hlöt",
    kpiId: "QAP_Käynnit_KPI_2",
    comparingKpiId: "QAP_Käynnit_KPI_2_EDV",
    kpiValue: 2,
    isInteger: true
  },
  {
    title: "Terveys ja seurantakäynnit KL1 kpl",
    kpiId: "QAP_Käynnit_KPI_3",
    comparingKpiId: "QAP_Käynnit_KPI_3_EDV",
    kpiValue: 3,
    isInteger: true
  },
  {
    title: "Sairauskäynnit KL2 kpl",
    kpiId: "QAP_Käynnit_KPI_4",
    comparingKpiId: "QAP_Käynnit_KPI_4_EDV",
    kpiValue: 4,
    isInteger: true
  }
];

export const visitAndRegistrationPageCharts: Chart[] = [
  { title: "Trendikuvaaja", objectId: "juRtJ", noSelections: true },
  {
    title: "Organisaatio kuvaaja valittavilla tasoilla",
    objectId: "PGPvdb",
    dimensionName: "vL.Käynnit_set_org_dimension",
    dimensionSelectionIds: [
      "Konserni",
      "Yritys",
      "Asiakasnumero",
      "Osasto",
      "Kustannuspaikka"
    ],
    noSelections: true
  },
  {
    title: "Syventävä kuvaaja valittavilla muuttujilla",
    objectId: "cJscXnW",
    dimensionName: "vL.Käynnit_set_dimension",
    dimensionSelectionIds: [
      "Käynnin syy",
      "Terveystarkastukset",
      "Ammattiryhmä",
      "Ikäryhmä",
      "Diagnoosikategoria"
    ],
    noSelections: false
  },
  {
    title: "Käynnit Kela luokittain",
    objectId: "dakVmKP",
    noSelections: false
  }
];
