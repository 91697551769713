import { SwipeableDrawer, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Icon, Logo } from "@terveystalo/design-system-react-components";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AccordionComponent from "../AccordionComponent/AccordionComponent";
import AccordionItem from "../AccordionComponent/parts/AccordionItem";
import LanguageSelection from "./parts/LanguageSelection";
import { useSideMenuComponentStyles } from "./SideMenuComponent.styles";

type SideMenuComponentProps = {
  menuOpen: boolean;
  toggleMenu: (toggle: boolean) => void;
};

const SideMenuComponent = (props: SideMenuComponentProps) => {
  const { menuOpen, toggleMenu } = props;
  const { t } = useTranslation();
  const classes = useSideMenuComponentStyles();

  return (
    <SwipeableDrawer
      anchor="left"
      open={menuOpen}
      disableScrollLock
      PaperProps={{
        className: classes.drawerPaper,
        elevation: 0
      }}
      BackdropProps={{ invisible: true }}
      onClose={() => toggleMenu(false)}
      onOpen={() => toggleMenu(true)}
    >
      <Box className={classes.mainContainer}>
        <Box className={classes.head}>
          <Box
            className={classes.closeButton}
            onClick={() => toggleMenu(false)}
          >
            <Icon name="Close"></Icon>
          </Box>
          <Link to="/">
            <Logo className={classes.logo} name="TerveystaloNew" />
          </Link>
        </Box>
        <a
          href={process.env.REACT_APP_SUUNTA_LINK}
          className={classes.returnToLink}
        >
          <Box px="1.5rem">
            <Icon name="ArrowLeft" />
          </Box>
          <Typography variant="button">
            {t("SideMenu.ReturnToSuunta")}
          </Typography>
        </a>
        <AccordionComponent />
        <Box className={classes.SecondTitle} > 
        <Typography variant="h6" color="primary.dark">
            {t("SideMenu.Possession")}
          </Typography>
          </Box>
          <AccordionItem 
            iconName="Book"
            label={t("SideMenu.Manual")}
            href={process.env.REACT_APP_DOWNLOAD_MANUAL_LINK ?? '#'}
            secondIcon="Download"
            download
          />
        <LanguageSelection />
      </Box>
    </SwipeableDrawer>
  );
};

export default SideMenuComponent;
