import { FieldOpt } from "../../../types/qlik-types";

export enum ContextActionEnum {
    ChangeVariable = "ChangeVariable",
    Clear = "Clear",
    HandleChange = "HandleChange",
    Reset = "Rest",
    SetSorting = "SetSorting",
    RestTwo = 'RestTwo'

}

export enum ContextStateVariables {
  Open = "open",
  Selections = "selections",
  SelectedDisplay = 'selectedDisplay',
  SortedArray = 'sortedArray',
  Values = 'values',
  MonthYearDefaults = 'monthYearDefaults',
  QuarterDefaults = "quarterDefaults"

}

export interface ContextStateProps {
  open: boolean;
  selections: any[];
  selectedDisplay: string[];
  sortedArray: string[];
  values: FieldOpt[];
  monthYearDefaults: FieldOpt[];
  quarterDefaults: FieldOpt[];
  hashNum: boolean | undefined;
}

export interface ContextActionProps {
  type: ContextActionEnum;
  playload?: {
    variable?: ContextStateVariables;
    value: any;
  };
}
