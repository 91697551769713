import { ContextStateProps } from "./ReducerIntEnums";

const reducerDefaultValues = (hashNum : boolean | undefined): ContextStateProps => {
  return {
    open: false,
    selections: [],
    selectedDisplay: [],
    sortedArray: [],
    values: [],
    monthYearDefaults: [],
    quarterDefaults: [],
    hashNum
  };
};

export default reducerDefaultValues;
