import { Box } from "@mui/system";
import ChartComponent from "../../components/ChartComponent/ChartComponent";
import { Chart } from "../../types/componentTypes/Chart";
import { sickLeavesPageCharts } from "../../utils/sickLeavesPage.service";
import { useSickLeavesPageStyles } from "./SickLeavesPage.styles";

type SickLeavesPageProps = {
  styleClass?: string;
};

const SickLeavesPage = (props: SickLeavesPageProps) => {
  const classes = useSickLeavesPageStyles();
  const { styleClass } = props;

  return (
    <Box className={`${classes.root} ${styleClass}`}>
      <Box className={classes.contentContainer}>
        {sickLeavesPageCharts.map((chart: Chart) => (
          <ChartComponent
            key={
              Array.isArray(chart.objectId) ? chart.objectId[0] : chart.objectId
            }
            title={chart.title}
            dimensionName={chart.dimensionName}
            dimensionSelectionIds={chart.dimensionSelectionIds}
            objectId={chart.objectId}
            noSelections={chart.noSelections}
          />
        ))}
      </Box>
    </Box>
  );
};

export default SickLeavesPage;
