import { Chart } from "../types/componentTypes/Chart";
import { KPIMeter } from "../types/componentTypes/KPIMeter";

export const expensePageKPIMeters: KPIMeter[] = [
  {
    title: "Työterveyden kustannukset €",
    kpiId: "QAP_Kustannukset_KPI_1",
    comparingKpiId: "QAP_Kustannukset_KPI_1_EDV",
    kpiValue: 1
  },
  {
    title: "Sairauspoissalon kustannukset €",
    kpiId: "QAP_Kustannukset_KPI_2",
    comparingKpiId: "QAP_Kustannukset_KPI_2_EDV",
    kpiValue: 2
  },
  {
    title: "KL1 Kustannukset %",
    kpiId: "QAP_Kustannukset_KPI_3",
    comparingKpiId: "QAP_Kustannukset_KPI_3_EDV",
    kpiValue: 3,
    isPercent: true,
    oneDecimal: true,
  },
  {
    title: "KL2 Kustannukset %",
    kpiId: "QAP_Kustannukset_KPI_4",
    comparingKpiId: "QAP_Kustannukset_KPI_4_EDV",
    kpiValue: 4,
    isPercent: true,
    oneDecimal: true
  },
  {
    title: "Ei KELA korvattavat %",
    kpiId: "QAP_Kustannukset_KPI_5",
    comparingKpiId: "QAP_Kustannukset_KPI_5_EDV",
    kpiValue: 5,
    isPercent: true,
    oneDecimal: true
  }
];

export const expensePageCharts: Chart[] = [
  { title: "Trendikuvaaja", objectId: "FZjXrf", noSelections: true },
  {
    title: "Organisaatio kuvaaja valittavilla tasoilla",
    objectId: "hHwmjaU",
    dimensionSelectionIds: [
      "Konserni",
      "Yritys",
      "Asiakasnumero",
      "Osasto",
      "Kustannuspaikka"
    ],
    dimensionName: "vL.Käynnit_set_org_dimension",
    noSelections: true
  },
  {
    title: "Syventävä kuvaaja valittavilla muuttujilla",
    objectId: "jKXGpMT",
    dimensionName: "vL.Kustannukset_set_dimension",
    dimensionSelectionIds: ["Tuoteryhmä seloste", "Ammattiryhmä"],
    noSelections: false
  },
  {
    title: "Kustannukset Kela luokittain",
    objectId: "JRXPxHw",
    noSelections: false
  }
];
