import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Icon } from "@terveystalo/design-system-react-components";
import React, { useCallback, useEffect } from "react";
import { useQlikAppContext } from "../../context/QlikAppContext";
import { useToggleComponentStyles } from "./ToggleComponent.styles";

type ToggleComponentProps = {
  title: string;
  dimensionName: string;
  selectionValues: string[];
  selectionTexts: string[];
};

const ToggleComponent = (props: ToggleComponentProps) => {
  const { title, dimensionName, selectionValues, selectionTexts } = props;
  const classes = useToggleComponentStyles();
  const [selection, setSelection] = React.useState(selectionValues[0]);

  const { app, qlikAppLoading } = useQlikAppContext();

  const initSelection = useCallback(async () => {
    if (app && !qlikAppLoading) {
      await app.variable.getContent(dimensionName, (res) => {
        setSelection(res.qContent.qString);
      });
    }
  }, [app, dimensionName, qlikAppLoading]);

  const toggleSelection = useCallback(
    async (event: any, newVal: any) => {
      if (app && !qlikAppLoading) {
        await app.variable.setStringValue(dimensionName, newVal);
        await initSelection();
      }
    },
    [app, dimensionName, initSelection, qlikAppLoading]
  );

  useEffect(() => {
    (async () => {
      await initSelection();
    })();
  }, [initSelection]);

  return (
    <Box className={classes.root}>
      <Typography p="1rem 0 0.5rem 1.5rem" variant="subtitle1">
        {title}
      </Typography>
      <ToggleButtonGroup
        className={classes.toggleGroup}
        value={selection}
        exclusive
        onChange={toggleSelection}
      >
        <ToggleButton value={selectionValues[0]}>
          {selection === selectionValues[0] && (
            <Icon color="white" name="Check" />
          )}
          <Typography variant="subtitle1" pl={1}>
            {selectionTexts[0]}
          </Typography>
        </ToggleButton>
        <ToggleButton value={selectionValues[1]}>
          {selection === selectionValues[1] && (
            <Icon color="white" name="Check" />
          )}
          <Typography variant="subtitle1" pl={1}>
            {selectionTexts[1]}
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

export default ToggleComponent;
