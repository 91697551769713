import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  useReducer
} from "react";
import QlikAppLoader from "../services/qlik-app";
import { environment } from "../environments/environment";
import { QlikApp } from "../types/qlik-types";
import reducerDefaultValues from "./contextReducer/ReducerDefault";
import reducer from "./contextReducer/ReducerFunctions";
import { ContextActionEnum } from "./contextReducer/ReducerEnums";
import { useLocation } from "react-router-dom";

const useContextApp = () => {
  const [loading, setLoading] = useState(true);
  const [qlikAppError, setQlikAppError] = useState<boolean | undefined>(
    undefined
  );
  const [app, setApp] = useState<QlikApp>({} as QlikApp);
  const [state, dispatch] = useReducer(reducer, reducerDefaultValues);
  const setUpdateGraph = () =>
    dispatch({ type: ContextActionEnum.UpdateGraphOP });

  useEffect(() => {
    if (JSON.stringify(app) === "{}") {
      (async () => {
        // Load the default application
        const appRes = await QlikAppLoader(
          environment.appId,
          environment.qlikConfig,
          setQlikAppError
        ).catch((err: Error) => {
          setQlikAppError(true);
        });
        console.log("Update one side");
        setApp(appRes);
      })();
    } else {
      if (app) {
        dispatch({ type: ContextActionEnum.SetTokenUsername });
        // To do

        app.bookmark
          .apply("e8dc95ca-02eb-41bd-b630-d6c22b1d6ff6")
          .then(() => {
            setTimeout(() => {
              setLoading(false);
            }, 100)
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  }, [app]);

  return { state, loading, qlikAppError, app, setUpdateGraph };
};

export default useContextApp;
