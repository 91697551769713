import { Box } from "@mui/system";
import ChartComponent from "../../components/ChartComponent/ChartComponent";
import { Chart } from "../../types/componentTypes/Chart";
import { expensePageCharts } from "../../utils/expensePage.service";
import { useExpensePageStyles } from "./ExpensePage.styles";

type ExpensePageProps = {
  styleClass?: string;
};

const ExpensePage = (props: ExpensePageProps) => {
  const classes = useExpensePageStyles();
  const { styleClass } = props;

  return (
    <Box className={`${classes.root} ${styleClass}`}>
      <Box className={classes.contentContainer}>
        {expensePageCharts.map((chart: Chart) => (
          <ChartComponent
            key={chart.objectId}
            dimensionName={chart.dimensionName}
            dimensionSelectionIds={chart.dimensionSelectionIds}
            title={chart.title}
            objectId={chart.objectId}
            noSelections={chart.noSelections}
          />
        ))}
      </Box>
    </Box>
  );
};

export default ExpensePage;
