import { createStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useCustomTableStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#fff",
      margin: "0 1.5rem 2rem",
      borderRadius: "4px"
    },
    titleContainer: {
      padding: "1rem 1.5rem 1rem",
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    },
    table: {
      overflow: "auto",
      maxHeight: "50vh"
    },
    tableHeader: {
      backgroundColor: "#F6F6F6 !important"
    },
    tableHeaderCell: {
      fontWeight: "bold",
      color: "#000000"
    },
    iconStyle: {
      width: "2rem",
      height: "2rem"
    },
    iconContainer: {
      display: "flex",
      flexDirection: "row-reverse",
      padding: "0.5rem 1.5rem",
      marginLeft: "auto",
      gap: "2rem",
      cursor: "pointer",
      backgroundColor: "background.paper"
    },
    modalContainer: {
      display: "flex",
      flexDirection: "column",
      width: "90%",
      height: "90%",
      backgroundColor: "#fff",
      transform: "translate(5%, 5%)"
    }
  })
);
