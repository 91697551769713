import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useQlikAppContext } from "../../context/QlikAppContext";
import { createHyperCubeDef } from "../../utils/qlikUtils";
import { useAbsenceComponentStyles } from "./AbsenseComponent.styles";
import { Icon } from "@terveystalo/design-system-react-components";

type AbsenceStatsProps = {
  positiveMeasureName: string;
  negativeMeasureName: string;
  neutralMeasureName: string;
};

const AbsenceStats = (props: AbsenceStatsProps) => {
  const { positiveMeasureName, negativeMeasureName, neutralMeasureName } =
    props;
  const { app, qlikAppLoading } = useQlikAppContext();
  const classes = useAbsenceComponentStyles();

  const [positive, setPositive] = useState("0");
  const [negative, setNegative] = useState("0");
  const [neutral, setNeutral] = useState("0");
  
  useEffect(() => {
    if (app && !qlikAppLoading) {
      const hcd = createHyperCubeDef([
        positiveMeasureName,
        negativeMeasureName,
        neutralMeasureName
      ]);

      app.createCube(hcd, (hc: any) => {
        const matrix = hc.qHyperCube.qDataPages[0].qMatrix[0];
        // Math.floor(matrix[0].qText.replace(',', '.') as any).toString()

        setPositive(
          isNaN(matrix[0].qNum)
            ? matrix[0].qText
            : matrix[0].qNum
        );
        setNegative(
          isNaN(matrix[1].qNum)
            ? matrix[1].qText
            : matrix[1].qNum
        );
        setNeutral(
          isNaN(matrix[2].qNum)
            ? matrix[2].qText
            : matrix[2].qNum
        );
      });
    }
    return () => {};
  }, [
    app,
    negativeMeasureName,
    neutralMeasureName,
    positiveMeasureName,
    qlikAppLoading
  ]);

  return (
    <Box className={classes.dataDisplay}>
      <Grid
        container
        direction="row"
        width="5rem"
        justifyContent="space-between"
      >
        <Typography color="primary.contrastText" variant="body2">
          {positive}
        </Typography>
        <Icon color="attentionRed" name="ArrowUp" />
      </Grid>
      <Grid
        container
        direction="row"
        width="5rem"
        justifyContent="space-between"
      >
        <Typography color="primary.contrastText" variant="body2">
          {negative}
        </Typography>
        <Icon color="okayGreen" name="ArrowDown" />
      </Grid>
      <Grid
        container
        direction="row"
        width="5rem"
        justifyContent="space-between"
      >
        <Typography color="primary.contrastText" variant="body2">
          {neutral}
        </Typography>
        <Icon color="matisseBlue" name="Line" />
      </Grid>
    </Box>
  );
};

export default AbsenceStats;




