import { Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

export const useAppStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentContainer: {
      display: "grid",
      gridTemplateAreas: `
        'header header'
        'sideNav kpi'
        'sideNav content'
        `,
      gridTemplateColumns: "auto 1fr",
      gridTemplateRows: "auto auto 1fr",
      [theme.breakpoints.down("md")]: {
        gridTemplateAreas: `
        'header'
        'kpi'
        'sideNav'
        'content'
        `,

        gridTemplateColumns: "minmax(100px, 1fr)",
        gridTemplateRows: "minmax(100px, 1fr)"
      }
    },
    routeContainer: {
      gridArea: "content",
      borderLeft: "1px solid #99B5DE",
      [theme.breakpoints.down("md")]: {
        paddingTop: "2rem",
        backgroundColor: theme.palette.primary.light
      }
    },
    sideNavContainer: {
      gridArea: "sideNav",
      zIndex: 10,
      position: "sticky",
      top: 0,
      [theme.breakpoints.down("md")]: {
        backgroundColor: "#e6f5fb"
      }
    },
    headerContainer: {
      gridArea: "header"
    },
    kpiContainer: {
      gridArea: "kpi"
    }
  })
);
