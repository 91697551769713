import { createStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useKpiContainerStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      overflow: "auto",
      padding: "2rem",
      backgroundColor: (theme.palette.primary as any).darker,
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center"
      }
    }
  })
);
