import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useChartComponentStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#fff",
      margin: "0 2rem 1.5rem 2rem",
      borderRadius: "4px",
      height: "100%"
    },
    titleContainer: {
      padding: "1rem 1.5rem 1rem",
      display: "flex",
      flexDirection: "row"
    },
    allBlock: {
      display: (showChart) => (showChart ? "block" : "none"),
    },
    iconContainer: {
      marginLeft: "auto",
      display: "flex",
      flexDirection: "row",
      gap: "2rem"
    },
    iconButton: {
      cursor: "pointer"
    },
    chartContainer: {
      padding: "1.5rem",
      height: "100%"
    },
    multiChartSelection: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      padding: "1.5rem",
      gap: "2rem",
      [theme.breakpoints.down("md")]: {
        gap: "1rem"
      }
    },
    multiChartLink: {
      // paddingRight: "2rem",
      color: `${theme.palette.primary.dark} !important`,
      cursor: "pointer",
      "&:hover": {
        color: `${theme.palette.primary.main} !important`
      }
    },
    activeLink: {
      textDecorationLine: "underline",
      textDecorationThickness: "3px",
      textUnderlineOffset: "4px"
    },
    modalContainer: {
      display: "flex",
      flexDirection: "column",
      width: "90%",
      height: "90%",
      backgroundColor: "#fff",
      transform: "translate(5%, 5%)"
    }
  })
);
