import React from "react";
import { Box } from "@mui/system";
import { Icon } from "@terveystalo/design-system-react-components";
import { Typography } from "@mui/material";
import { useSideMenuComponentStyles } from "../SideMenuComponent.styles";
import { useTranslation } from "react-i18next";

type LanguageSelectionProps = {};

const LanguageSelection = (props: LanguageSelectionProps) => {
  const classes = useSideMenuComponentStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.languageContainer}>
      <Icon name="Globe" />
      <Typography variant="button">
        {t("SideMenu.LanguageSelection.fi")}
      </Typography>
      <Typography variant="button">
        {t("SideMenu.LanguageSelection.sv")}
      </Typography>
      <Typography variant="button">
        {t("SideMenu.LanguageSelection.en")}
      </Typography>
    </Box>
  );
};

export default LanguageSelection;
