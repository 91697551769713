import { Box } from "@mui/system";
import { useNavBarStyles } from "./SideNavBar.styles";
import { Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import MultiSelectComponent from "../MultiSelectComponent/MultiSelectComponent";
import { useLocation } from "react-router-dom";
import ToggleComponent from "../ToggleComponent/ToggleComponent";
import { Icon } from "@terveystalo/design-system-react-components";
import InputQlikVariable from "../InputQlikVariable/InputQlikVariable";
import useNavCollapsed from "../../hooks/useNavCollapsed";
import { useState, useEffect } from "react";
import { ResetCounter } from "../../types/componentTypes/Chart";
import { useQlikAppContext } from "../../context/QlikAppContext";
import { createMultiSelectValuesList } from "../../utils/qlikUtils";
import { FieldOpt } from "../../types/qlik-types";
import { shouldUpdate, usePreviousLocation } from "../../utils/utilFunctions";

type SideNavBarProps = {
  styleClass?: string;
};
interface DefaultValues {
  monthYearDefault: any[];
  quarterDefault: any[];
}

const SideNavBar = (props: SideNavBarProps) => {
  const { t } = useTranslation();
  const { app, qlikAppLoading } = useQlikAppContext();
  const { navCollapsed, handleToggle } = useNavCollapsed();
  const classes = useNavBarStyles(navCollapsed);
  const { styleClass } = props;
  const { pathname } = useLocation();
  const previousLocation = usePreviousLocation();
  const [restCounter, setRestCounter] = useState<ResetCounter>({
    counterOne: 0,
    counterTwo: 0
  });
  const [pathChanged, setPathChanged] = useState<number>(0);
  const [defaultValues, setDefaultValues] = useState<DefaultValues>({
    monthYearDefault: [],
    quarterDefault: []
  });
  const [showMonthSelections, setShowMonthSelection] = useState<boolean>(false);
  const hideDisplayType =
    pathname === "/kpi" || pathname === "/workAbilityRisk";

  const handleCollapse = (e: any) => {
    if (e.currentTarget !== e.target) return;
    handleToggle(!navCollapsed);
  };

  // Source and key of solving the issue.
  useEffect(() => {
    if (app && !qlikAppLoading) {
      console.log("prevousLocation is " + previousLocation.pathname);
      if (
        shouldUpdate(pathname, previousLocation.pathname)
      ) {
        const notWorkAbility = pathname !== "/workAbilityRisk";
        setPathChanged(pathChanged + 1);
        console.log("pathChanged is " + pathChanged);
        setTimeout(() => {
          app.bookmark
            .apply(
              notWorkAbility
                ? "e8dc95ca-02eb-41bd-b630-d6c22b1d6ff6"
                : "68dc9eea-74f4-4f4a-8b68-d172944739e5"
            )
            .then(() => {
              setTimeout(() => {
                const lb = createMultiSelectValuesList(
                  notWorkAbility ? "MonthYear" : "VuosiKvartaali"
                );
                app.createList(lb as any).then((yup) => {
                  const mat = yup.layout.qListObject.qDataPages[0].qMatrix;
                  let mappedMat = mat.map((i: any) => i[0]);
                  setDefaultValues({
                    monthYearDefault: notWorkAbility ? mappedMat : [],
                    quarterDefault: !notWorkAbility ? mappedMat : []
                  });
                });
              }, 700);
            });
        }, 1400);

      }
      setShowMonthSelection(pathname === "/workAbilityRisk" ? false : true);
      console.log("previousLocation is ");
      console.log(previousLocation.pathname);
    }
  }, [pathname, previousLocation.pathname, app, qlikAppLoading]);

  return (
    <Box className={`${classes.root} ${styleClass}`} onClick={handleCollapse}>
      {navCollapsed ? (
        <Icon
          className={classes.titleIcon}
          name="Filters"
          onClick={handleCollapse}
        />
      ) : null}
      <Box className={classes.fullSideBar}>
        <Box className={classes.sideBarTitle} onClick={handleCollapse}>
          <Typography variant="h6">{t("Filters")}</Typography>
          <Icon
            className={classes.titleIcon}
            name="Filters"
            onClick={handleCollapse}
          />
        </Box>
        <MultiSelectComponent
        //  pathVar={pathChanged}
          restCounter={restCounter}
          field="Year"
          fieldTitle="Vuosi"
          hasqNum
        />

  {showMonthSelections &&  <MultiSelectComponent
        //  pathVar={pathChanged}
          restCounter={restCounter}
          field="VuosiKvartaali"
          fieldTitle={t("SideNavigation.Quarter")}
        />}
        
        {!showMonthSelections &&  <MultiSelectComponent
        //  pathVar={pathChanged}
          restCounter={restCounter}
          field="VuosiKvartaali"
          fieldTitle={t("SideNavigation.Quarter")}
          defaultValues={defaultValues.quarterDefault}
        />}

        {showMonthSelections && (
          <Box>
            <MultiSelectComponent
              pathVar={pathChanged}
              restCounter={restCounter}
              field="Month"
              fieldTitle="Kuukausi"
              hasqNum
            />
            <MultiSelectComponent
              pathVar={pathChanged}
              restCounter={restCounter}
              field="MonthYear"
              fieldTitle="Kuukausi vuosi"
              hasqNum
              defaultValues={defaultValues.monthYearDefault}
            />{" "}
          </Box>
        )}

        <MultiSelectComponent
          pathVar={pathChanged}
          field="Konserni"
          restCounter={restCounter}
        />
        <MultiSelectComponent
          pathVar={pathChanged}
          field="Yritys"
          restCounter={restCounter}
        />
        <MultiSelectComponent
          pathVar={pathChanged}
          field="Asiakasnumero"
          restCounter={restCounter}
        />
        <MultiSelectComponent
          pathVar={pathChanged}
          field="Osasto"
          restCounter={restCounter}
        />
        <MultiSelectComponent
          pathVar={pathChanged}
          field="Kustannuspaikka"
          restCounter={restCounter}
        />
        {!hideDisplayType && (
          <ToggleComponent
            title={t("SideNavigation.DisplayType")}
            dimensionName="vL.Esitystyyppi"
            selectionValues={["1", "2"]}
            selectionTexts={[
              t("SideNavigation.Button.QuantityShort"),
              t("SideNavigation.Button.PersonShort")
            ]}
          />
          )}
        {(pathname === "/sickLeaves" || pathname === "/kpi") && (
          <ToggleComponent
            title="Sairauspoissaolon laskenta"
            dimensionName="vL.EK_sairauspäivät"
            selectionValues={[
              "[LippuArkipäivä] = {0,1}",
              "[LippuArkipäivä] = {1}"
            ]}
            selectionTexts={[
              t("SideNavigation.Button.Show"),
              t("SideNavigation.Button.DoNotShow")
            ]}
          />
          )}
        {(pathname === "/expense" || pathname === "/kpi") && (
          <InputQlikVariable
            title="SickLeavePrice"
            variableName="vL.Sairauspoissaolopäivän_oletuskustannus"
          />
        )}
        <Box className={classes.buttonResetSection}>
          <Button
            variant="text"
            onClick={() =>
              setRestCounter({
                ...restCounter,
                counterOne: restCounter.counterOne + 1
              })
            }
          >
            {t("SideNavigation.Button.ResetTime")}
          </Button>
          <Button
            variant="text"
            onClick={() =>
              setRestCounter({
                ...restCounter,
                counterTwo: restCounter.counterTwo + 1
              })
            }
          >
            {t("SideNavigation.Button.Reset")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
export default SideNavBar;
