import { ContextStateProps } from "./ReducerEnums";

export const reducerDefaultValues: ContextStateProps = {
    username: "Username",
    updateGraph: 0,
    token: ""

};

export default reducerDefaultValues;
