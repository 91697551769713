import { createStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useTabComponentStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: (props) => (!!props ? "#fff" : "transparent"),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minWidth: "12.5rem",
      borderRadius: "8px",
      padding: "0.75rem",
      cursor: "pointer"
    },
    changesContainer: {
      display: "flex",
      gap: "0.5rem",
      flexDirection: "row"
    },
    kpiContainer: {
      display: "flex",
      flexDirection: "row",
      padding: "2rem",
      backgroundColor: theme.palette.primary.dark
    }
  })
);
