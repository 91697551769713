import {
  ContextActionEnum,
  ContextActionProps,
  ContextStateProps
} from "./ReducerEnums";
import  jwt_decode from 'jwt-decode';

const reducer = (
  state: ContextStateProps,
  action: ContextActionProps
): ContextStateProps => {
  switch (action.type) {
    case ContextActionEnum.ChangeVariable: {
      if (action.playload) {
        if (action.playload.variable)
          return {
            ...state,
            [action.playload.variable]: action.playload.value
          };
        return state;
      }
      return state;
    }
    case ContextActionEnum.SetTokenUsername: {
      const stortedToken: string | null = localStorage.getItem("current-token");
      if (stortedToken) {
        const tokenDecoded = jwt_decode(stortedToken);
        return {
          ...state,
          token: stortedToken,
          username: (tokenDecoded as any).user.name
        };
      }
      return state;
    }
    case ContextActionEnum.UpdateGraphOP: {
        return {...state, updateGraph: state.updateGraph + 1}
    }
  }
};

export default reducer;

