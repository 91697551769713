import { useEffect, useState } from "react";
import { useQlikAppContext } from "../../context/QlikAppContext";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useCustomTableStyles } from "./CustomTable.styles";
import { HyperCubeDef } from "../../types/qlik-types";
import React from "react";
import { Box } from "@mui/system";
import { Icon } from "@terveystalo/design-system-react-components";
import { Modal, Skeleton, Typography } from "@mui/material";
export type LibraryLabelId = {
  name: string;
  qLabel: string;
  qLibraryId: string;
  objectType: "dimension" | "measure" | "field";
};

type CustomTableProps = {
  dimensions: LibraryLabelId[];
  measures: LibraryLabelId[];
  title: string;
  customSort?: boolean;
};

const defaultProps: CustomTableProps = {
  dimensions: [
    { name: "", qLabel: "", qLibraryId: "", objectType: "dimension" }
  ],
  measures: [{ name: "", qLabel: "", qLibraryId: "", objectType: "measure" }],
  title: ""
};

const CustomTable = (props: CustomTableProps) => {
  const { dimensions, measures, title, customSort } = props;
  const { app, qlikAppLoading } = useQlikAppContext();
  const classes = useCustomTableStyles();
  const [dataMatrix, setDataMatrix] = React.useState<any[]>([]);
  const [qId, setQId] = React.useState("");

  const [isFullscreen, setFullscreen] = useState(false);

  function exportData() {
    app.visualization.get(qId).then(function (vis) {
      vis
        .exportData({ format: "OOXML", state: "A" })
        .then(function (link: any) {
          window.open(link);
        });
    });
  }

  const toggleFullscreen = () => {
    setFullscreen(!isFullscreen);
  };

  const compare = (a: any, b: any) => {
    if (!isFinite(a) && !isFinite(b)) {
      return 0;
    }
    if (!isFinite(a)) {
      return 1;
    }
    if (!isFinite(b)) {
      return -1;
    }
    return a - b;
  };

  useEffect(() => {
    if (app && !qlikAppLoading) {
      const hcd: HyperCubeDef = {
        qInitialDataFetch: [
          {
            qHeight: 200,
            qWidth: 7
          }
        ],
        qDimensions: dimensions.map((dimension: LibraryLabelId) => {
          const dimensionDef = {
            qLabel: dimension.qLabel,
            qLibraryId: dimension.qLibraryId,
            qNullSuppression: true,
            qOtherTotalSpec: {
              qOtherMode: "OTHER_OFF",
              qOtherSortMode: "OTHER_SORT_DESCENDING",
              qOtherCounted: {
                qv: "5"
              },
              qOtherLimitMode: "OTHER_GE_LIMIT"
            }
          };
          const fieldDef = {
            ...dimensionDef,
            qDef: { qFieldDefs: [dimension.qLibraryId] },
            qLibraryId: null
          };

          return dimension.objectType === "dimension" ? dimensionDef : fieldDef;
        }),
        qMeasures: measures.map((measure: LibraryLabelId) => {
          return {
            qLabel: measure.qLabel,
            qLibraryId: measure.qLibraryId,
            qSortBy: {
              qSortByState: 0,
              qSortByFrequency: 0,
              qSortByNumeric: 0,
              qSortByAscii: 1,
              qSortByLoadOrder: 0,
              qSortByExpression: 0,
              qExpression: {
                qv: " "
              }
            }
          };
        }),
        qSuppressZero: false,
        qSuppressMissing: false,
        qMode: "S",
        qInterColumnSortOrder: [],
        qStateName: "$"
      } as unknown as HyperCubeDef;

      app.createCube(hcd, (reply) => {
        setQId(reply.qInfo.qId);
        console.log(reply.qHyperCube.qDataPages[0]);
        const sortMatrix = reply.qHyperCube.qDataPages[0].qMatrix.sort((a, b) =>
          compare(a[0].qNum, b[0].qNum)
        );
        setDataMatrix(
          customSort ? sortMatrix : reply.qHyperCube.qDataPages[0].qMatrix
        );
      });
    }
  }, [app, qlikAppLoading, customSort, dimensions, measures]);

  const table = (
    <>
      <Box className={classes.titleContainer}>
        <Typography variant="h6">{title}</Typography>
        <Box className={classes.iconContainer}>
          <Icon
            name={isFullscreen ? "Close" : "ExternalLink"}
            className={classes.iconStyle}
            onClick={toggleFullscreen}
          />
          <Icon
            name="Document"
            className={classes.iconStyle}
            onClick={() => exportData()}
            alt="Excel export"
          />
        </Box>
      </Box>
      {qlikAppLoading ? (
        <Skeleton
          width="100%"
          height="50rem"
          variant="rectangular"
          animation="wave"
        />
      ) : (
        <TableContainer component={Paper}>
          <Table stickyHeader className={classes.table}>
            <TableHead className={classes.tableHeader}>
              <TableRow className={classes.tableHeader}>
                {dimensions.map((cell: LibraryLabelId) => (
                  <TableCell
                    className={classes.tableHeader}
                    key={cell.name}
                    align="left"
                  >
                    {cell.name}
                  </TableCell>
                ))}
                {measures.map((cell) => (
                  <TableCell
                    className={classes.tableHeader}
                    key={cell.name}
                    align="right"
                  >
                    {cell.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {dataMatrix.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  {row.map((cell: any, index: any) => (
                    <TableCell
                      key={index}
                      align={index <= dimensions.length - 1 ? "left" : "right"}
                    >
                      {cell.qText}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );

  return (
    <Box className={classes.root}>
      {isFullscreen ? (
        <Modal open={isFullscreen} onClose={() => setFullscreen(false)}>
          <Box className={classes.modalContainer}>{table}</Box>
        </Modal>
      ) : (
        table
      )}
    </Box>
  );
};

CustomTable.defaultProps = defaultProps;

export default CustomTable;
