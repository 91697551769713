import { createStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useToggleComponentStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      borderBottom: `1px solid ${theme.palette.primary.light}`
    },
    toggleGroup: {
      padding: "0 0 1.5rem 1.5rem"
    }
  })
);
