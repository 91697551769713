import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useSideMenuComponentStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerModal: {
      backgroundColor: `${theme.palette.secondary.light} !important`,
      boxShadow: "none"
    },
    mainContainer: {
      width: "24rem",
      [theme.breakpoints.down("md")]: {
        width: "100%"
      }
    },
    closeButton: {
      width: "5rem",
      height: "5rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRight: `1px solid ${theme.palette.primary.light}`,
      cursor: "pointer",
      "&:hover": {
        transition: "all .15s ease-in-out",
        backgroundColor: `${theme.palette.primary.light} !important`
      }
    },
    head: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "#fff"
    },
    logo: {
      marginLeft: "2rem",
      marginRight: "1rem",
      cursor: "pointer"
    },
    returnToLink: {
      height: "3.5rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      backgroundColor: "#F1F7FB",
      textDecoration: "none",
      "&:hover": {
        backgroundColor: "#d9e3f3"
      }
    },
    languageContainer: {
      display: "flex",
      alignItems: "center",
      padding: "2rem 0 2rem 1.5rem",
      gap: "1rem"
    },
    drawerPaper: {
      backgroundColor: "#F6F6F6",
      borderRight: "1px solid #D9E3F3",
      width: "24rem",
      [theme.breakpoints.down("md")]: {
        width: "100%"
      }
    },
    SecondTitle: {
      borderBottom: `1px solid ${theme.palette.primary.light}`,
      paddingLeft: "1.5rem",
      paddingTop: "1rem",
      paddingBottom: "1rem"

    },
  })
);
