/* eslint-disable import/no-anonymous-default-export */
import getJWT from "./jwt-service";

const loadDependancy = (dep: any) => {
  return new Promise((resolve) => {
    (window as any).require([dep], (x: any) => {
      resolve(x);
    });
  });
};

const loadJs = (path: string) => {
  return new Promise<void>((resolve) => {
    const head = document.getElementsByTagName("head")[0];
    const script = document.createElement("script");
    script.src = path;
    head.appendChild(script);
    script.onload = () => {
      resolve();
    };
  });
};

const loadCss = (path: string, callback: any) => {
  return new Promise<void>((resolve) => {
    const head = document.getElementsByTagName("head")[0];
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = path;
    // head.appendChild(link);
    head.prepend(link);
    link.onload = () => {
      resolve();
    };
    link.onerror = () => {
      callback(true);
    }
  });
};
//Conf
export default async (requireConfig: any, callback: any) => {
  console.log("Loading Qlik Dependencies");
  await getJWT();
  await loadCss(`${requireConfig.baseUrl}/autogenerated/qlik-styles.css`, callback);
  await loadJs(`${requireConfig.baseUrl}/assets/external/requirejs/require.js`);
  (window as any).require.config(requireConfig);
  const qlik = await loadDependancy("js/qlik");
  return qlik;
};
