import { Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

export const useAccordionComponentStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: "3.5rem",
      border: "1px solid primary.light",
      boxShadow: "none"
    },
    accordionTitle: {
      borderBottom: `1px solid ${theme.palette.primary.light}`,
      paddingLeft: "1.5rem"
    },
    accordionDetails: {
      padding: "0 !important",
      borderBottom: `1px solid #D9E3F3`,
      backgroundColor: "#F6F6F6"
    },
    accordionItem: {
      display: "flex",
      justifyContent:"space-between",
      paddingLeft: "1.5rem",
      paddingRight: "1.5rem",
      minHeight: "3.5rem",
      alignItems: "center",
      textDecoration: "none",
      "&:hover": {
        backgroundColor: "#d9e3f3"
      }
    },
    activeLink: {
      textUnderlinePosition: "under",
      textDecorationLine: "underline !important",
      textUnderlineOffset: "3px"
    },
    accordionBox: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center"
    }
  })
);
