import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import AbsenseComponent from "../../components/AbsenceComponent/AbsenseComponent";
import CustomTable, {
  LibraryLabelId
} from "../../components/CustomTable/CustomTable";
import { useWorkAbilityRiskPageStyles } from "./WorkAbilityRiskPage.styles";

type WorkAbilityRiskPageProps = {
  styleClass?: string;
  currentTab: number;
};

const phenomenomTableDimensions: LibraryLabelId[] = [
  {
    name: "Selittävä Ilmiö",
    qLabel: "Selittävä Ilmiö",
    qLibraryId: "fjJdja",
    objectType: "dimension"
  }
];

const phenomenomTableMeasures: LibraryLabelId[] = [
  {
    name: "Henkilöt, joilla ilmiö esiintynyt 24 kk aikana",
    qLabel: "QAP_Riski_selittävät_ilmiöt_24kk",
    qLibraryId: "JVavtJ",
    objectType: "measure"
  },
  {
    name: "Henkilöt, joilla ilmiö esiintynyt 12 kk aikana",
    qLabel: "QAP_Riski_selittävät_ilmiöt_12kk",
    qLibraryId: "79835c5c-8d77-49d6-a50f-8a0a0e0913bf",
    objectType: "measure"
  }
];

const WorkAbilityRiskPage = (props: WorkAbilityRiskPageProps) => {
  const { currentTab, styleClass } = props;
  const { t } = useTranslation();
  const classes = useWorkAbilityRiskPageStyles();

  return (
    <Box className={`${classes.root} ${styleClass}`}>
      <Box className={classes.contentContainer}>
        {currentTab === 0 ? (
          <CustomTable
            dimensions={phenomenomTableDimensions}
            measures={phenomenomTableMeasures}
            title={t("WorkAbilityRiskPage.PhenomenomTable.Title")}
            customSort
          />
        ) : (
          <AbsenseComponent />
        )}
      </Box>
    </Box>
  );
};

export default WorkAbilityRiskPage;
