import { createStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useWorkAbilityRiskPageStyles = makeStyles((theme: any) =>
  createStyles({
    root: { backgroundColor: theme.palette.primary.light },
    kpiContainer: {
      display: "flex",
      flexDirection: "row",
      overflow: "auto",
      padding: "2rem",
      minHeight: "7rem",
      backgroundColor: theme.palette.primary.darker
    },
    contentContainer: {
      marginTop: "-1.5rem",
      [theme.breakpoints.down("md")]: {
        marginTop: "1.5rem"
      }
    }
  })
);
