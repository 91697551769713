import { Chart } from "../types/componentTypes/Chart";
import { KPIMeter } from "../types/componentTypes/KPIMeter";

export const sickLeavesPageKPIMeters: KPIMeter[] = [
  {
    title: "Sairauspoissaolot kpl",
    kpiId: "QAP_Poissaolot_KPI_1",
    comparingKpiId: "QAP_Poissaolot_KPI_1_EDV",
    kpiValue: 1,
    isInteger: true
  },
  {
    title: "Sairauspoissaolot hlöt",
    kpiId: "QAP_Poissaolot_KPI_2",
    comparingKpiId: "QAP_Poissaolot_KPI_2_EDV",
    kpiValue: 2,
    isInteger: true
  },
  {
    title: "Sairauspoissaolojaksojen lkm",
    kpiId: "QAP_Poissaolot_KPI_3",
    comparingKpiId: "QAP_Poissaolot_KPI_3_EDV",
    kpiValue: 3,
    isInteger: true
  },
  {
    title: "Sairauspoissaoloprosentti",
    kpiId: "QAP_Poissaolot_KPI_4",
    comparingKpiId: "QAP_Poissaolot_KPI_4_EDV",
    kpiValue: 4,
    isPercent: true,
    oneDecimal: true
  },
  {
    title: "Terveysprosentti",
    kpiId: "QAP_Poissaolot_KPI_5",
    comparingKpiId: "QAP_Poissaolot_KPI_5_EDV",
    kpiValue: 5,
    isPercent: true,
    oneDecimal: true

  }
];

export const sickLeavesPageCharts: Chart[] = [
  {
    title: "Trendikuvaaja",
    objectId: "DhjCPt",
    noSelections: true
  },
  {
    title: "Organisaatio kuvaaja valittavilla tasoilla",
    objectId: "JyXaV",
    dimensionSelectionIds: [
      "Konserni",
      "Yritys",
      "Asiakasnumero",
      "Osasto",
      "Kustannuspaikka"
    ],
    dimensionName: "vL.Käynnit_set_org_dimension",
    noSelections: true
  },
  {
    title: "Syventävä kuvaaja valittavilla muuttujilla",
    objectId: "dPcw",
    dimensionSelectionIds: ["Diagnoosikategoria", "Sairauspoissaolotyyppi"],
    dimensionName: "vL.Poissaolot_set_dimension",
    noSelections: true
  },
  {
    title: "Poissaolojakso",
    objectId: "NWMAmJ",
    noSelections: false
  }
];
