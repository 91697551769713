/* eslint-disable import/no-anonymous-default-export */
import QlikDependencies from "./qlik-dependencies";

// Conf
export default async (id: any, config: any, callback: any) => {
  const { isSecure, host, port, prefix } = config;

  const protocol = isSecure ? "https://" : "http://";
  const portStr = port ? `:${port}` : "";

  const baseUrl = `${protocol}${host}${portStr}${prefix}resources`;
  const requireConfig = {
    baseUrl
  };

  const qlik = await QlikDependencies(requireConfig, callback);

  if (qlik) {
    return (qlik as any).openApp(id, config);
  }
  return null;
};
