import { InputAdornment, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { useInputComponentStyles } from "./InputQlikVariable.styles";
import { useQlikAppContext } from "../../context/QlikAppContext";
import { useTranslation } from "react-i18next";

type InputComponentProps = {
  title: string;
  variableName: string;
};

const InputQlikVariable = (props: InputComponentProps) => {
  const { title, variableName } = props;
  const [data, setData] = React.useState("");
  const [errorState, setErrorState] = React.useState(false);
  const [helpText, setHelpText] = React.useState("");
  const classes = useInputComponentStyles();
  const { app, qlikAppLoading } = useQlikAppContext();
  const { t } = useTranslation();

  const validateInput = (e: any) => {
    if (Number.isInteger(Number(e))) {
      setErrorState(false);
      setHelpText(t("Saved"));
      return true;
    } else {
      setErrorState(true);
      setHelpText(t("NumberValidation"));
      return false;
    }
  };

  // Set numeric Qlik variable value
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setData(event.target.value);

    if (validateInput(event.target.value)) {
      app.variable.setNumValue(variableName, Number(event.target.value));
    }
  };

  // Init variable value from Qlik
  useEffect(() => {
    if (app && !qlikAppLoading) {
      app.variable.getContent(variableName, (value) => {
        return setData(value.qContent.qString);
      });
    }
  }, [app, qlikAppLoading, variableName]);

  return (
    <Box className={classes.root}>
      <Typography variant="subtitle1">{t(title)}</Typography>
      <TextField
        className={classes.text}
        error={errorState}
        variant="outlined"
        margin="dense"
        InputProps={{
          startAdornment: <InputAdornment position="start">€</InputAdornment>
        }}
        value={data}
        onChange={handleChange}
        helperText={helpText}
      />
    </Box>
  );
};

export default InputQlikVariable;
