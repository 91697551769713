import { useEffect, useState } from "react";
import useWindowSize from "./useWindowSize";

const localStorageKey = "navCollapsed";

const useNavCollapsed = () => {
  const { windowWidth } = useWindowSize();
  const [navCollapsed, setNavCollapsed] = useState(() => {
    try {
      const item = window.localStorage.getItem(localStorageKey);
      return item === "true";
    } catch (error) {
      return false;
    }
  });

  const handleToggle = (collapse: boolean) => {
    window.localStorage.setItem(localStorageKey, JSON.stringify(collapse));
    setNavCollapsed(collapse);
  };

  useEffect(() => {
    if (windowWidth < 900) setNavCollapsed(true);
  }, [setNavCollapsed, windowWidth]);

  return {
    navCollapsed,
    handleToggle
  };
};

export default useNavCollapsed;
