const prefix = process.env.REACT_APP_QLIK_VIRTUAL_PROXY_PREFIX;

export const environment = {
  production: false,
  qlikConfig: {
    host: process.env.REACT_APP_QLIK_HOST,
    prefix,
    port: "443",
    isSecure: true
  },
  appId: process.env.REACT_APP_QLIK_ID
};
