import { jsx } from "@emotion/react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Icon, IconName } from "@terveystalo/design-system-react-components";
import React, { JSXElementConstructor } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAccordionComponentStyles } from "../AccordionComponent.styles";
import styled from "styled-components";

const ReactLink = styled.a``;

type AccordionItemProps = {
  iconName: IconName;
  label: string;
  href: string;
  secondIcon?: IconName;
  download?: boolean;
};

const AccordionItem = (props: AccordionItemProps) => {
  const { iconName, label, href, secondIcon, download } = props;
  const { pathname } = useLocation();
  const classes = useAccordionComponentStyles();

  const LinkCustom = (LinkU: any) => (
    <LinkU
      className={`${classes.accordionItem} ${
        pathname === href ? classes.activeLink : ""
      }`}
      to={download ? undefined : href}
      href={download ? href : undefined}
      target={download ?  "_blank" : undefined}
      download={download ? true : undefined}
    >
      <Box className={classes.accordionBox}>
        <Icon name={iconName} />
        <Typography variant="button" pl="1rem">
          {label}
        </Typography>
      </Box>
      {secondIcon && <Icon name={secondIcon} />}
    </LinkU>
  );

  return LinkCustom(download ? ReactLink : Link);
};

export default AccordionItem;
