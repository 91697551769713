import { createStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useKpiPageStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { height: "100%", backgroundColor: theme.palette.primary.light },
    contentContainer: {
      marginTop: "-1.5rem",
      [theme.breakpoints.down("md")]: {
        marginTop: "1.5rem"
      }
    }
  })
);
