import "./App.styles.ts";
import Header from "./components/Header/Header";
import { Route, HashRouter, Redirect } from "react-router-dom";
import { Box } from "@mui/material";
import SideNavBar from "./components/SideNavBar/SideNavBar";
import ExpensePage from "./Pages/ExpensePage/ExpensePage";
import SickLeavesPage from "./Pages/SickLeavesPage/SickLeavesPage";
import VisitAndRegistrationPage from "./Pages/VisitAndRegistrationPage/VisitAndRegistrationPage";
import KpiPage from "./Pages/KpiPage/KpiPage";
import WorkAbilityRiskPage from "./Pages/WorkAbilityRiskPage/WorkAbilityRiskPage";
import { useAppStyles } from "./App.styles";
import KpiContainer from "./components/KpiContainer/KpiContainer";
import { expensePageKPIMeters } from "./utils/expensePage.service";
import { sickLeavesPageKPIMeters } from "./utils/sickLeavesPage.service";
import { visitAndRegistrationPageKPIMeters } from "./utils/visitAndRegistrationPage.service";
import PageTitleComponent from "./components/PageTitleComponent/PageTitleComponent";
import PageTabs from "./Pages/WorkAbilityRiskPage/parts/PageTabs";
import { useEffect, useState } from "react";
import { useQlikAppContext } from "./context/QlikAppContext";
import SessionModal from "./components/SessionModal/SessionModal";
import { checkCookieWithPrefix } from "./utils/utilFunctions";

const App = () => {
  const classes = useAppStyles();
  const [currentTab, toggleTab] = useState(0);
  // S stands for session
  const { app, qlikAppLoading, qlikAppError } = useQlikAppContext();

  useEffect(() => {
    console.log("App changed");
    if (app && !qlikAppLoading && window) {
      window.history.pushState({}, "Suunta", "/");
      checkCookieWithPrefix("X-Qlik");
    }
  }, [app, qlikAppLoading]);


  return (
    <Box>
      <Box className={classes.contentContainer}>
        <HashRouter>
          <Header styleClass={classes.headerContainer} />
          <SideNavBar styleClass={classes.sideNavContainer} />
          <SessionModal showModal={qlikAppError ? true : false} />
          <Route exact path="/">
            <Redirect to="/visitAndRegistration" />
          </Route>
          <Route path="/visitAndRegistration">
            <KpiContainer
              kpiMeters={visitAndRegistrationPageKPIMeters}
              styleClass={classes.kpiContainer}
              kpiVariableName="vL.Käynnit_kpi_selection"
            />
            <VisitAndRegistrationPage styleClass={classes.routeContainer} />
          </Route>
          <Route path="/sickLeaves">
            <KpiContainer
              kpiMeters={sickLeavesPageKPIMeters}
              styleClass={classes.kpiContainer}
              kpiVariableName="vL.Poissaolot_kpi_selection"
            />
            <SickLeavesPage styleClass={classes.routeContainer} />
          </Route>
          <Route path="/expense">
            <KpiContainer
              kpiMeters={expensePageKPIMeters}
              styleClass={classes.kpiContainer}
              kpiVariableName="vL.Kustannukset_kpi_selection"
            />
            <ExpensePage styleClass={classes.routeContainer} />
          </Route>
          <Route path="/workAbilityRisk">
            <PageTabs
              currentTab={currentTab}
              toggleTab={toggleTab}
              styleClass={classes.kpiContainer}
            />
            <WorkAbilityRiskPage
              currentTab={currentTab}
              styleClass={classes.routeContainer}
            />
          </Route>
          <Route path="/kpi">
            <PageTitleComponent styleClass={classes.kpiContainer} />
            <KpiPage styleClass={classes.routeContainer} />
          </Route>
        </HashRouter>
      </Box>
    </Box>
  );
};

export default App;
