import { FormControl, MenuItem, Select } from "@mui/material";
import { Box } from "@mui/system";
import { Icon } from "@terveystalo/design-system-react-components";
import { useTranslation } from "react-i18next";
import { SortType } from "../../utils/Enums";
import { FieldOpt } from "../../types/qlik-types";
import useMultiSelectComponent from "./UseMultiSelectComponent";
import { ResetCounter } from "../../types/componentTypes/Chart";

export type MultiSelectProps = {
  title?: string;
  emptySelectionText?: string;
  field: string;
  fieldTitle?: string;
  sortCriteria?: object;
  hasqNum?: boolean;
  restCounter: ResetCounter;
  sortBy?: SortType;
  reloadDefault?: number;
  defaultValues?: any;
  pathVar?: number
};

const MultiSelectComponent = (props: MultiSelectProps) => {
  const { field, fieldTitle } = props;
  const { t } = useTranslation();
  const {
    classes,
    open,
    selectedDisplay,
    sortedArray,
    values,
    selections,
    handleChange,
    setOpen,
    menuItemStyle,
    clear
  } = useMultiSelectComponent(props);
  return (
    <Box className={classes.root}>
      <FormControl fullWidth variant="standard">
        <Select
          open={open}
          displayEmpty
          onClose={() => setOpen(false)}
          multiple
          renderValue={() => {
            if (selectedDisplay.length === 0) return fieldTitle ?? field;
            else return selectedDisplay.join(", ");
          }}
          IconComponent={(_props: any) => (
            <Icon
              name="Select"
              className={classes.selectIcon}
              onClick={() => setOpen(!open)}
            />
          )}
          MenuProps={{
            sx: {
              maxHeight: "30rem"
            }
          }}
          className={
            classes.selectInput &&
            (selections.length > 0 ? classes.selected : "")
          }
          value={selections}
        >
          <MenuItem className={classes.menuItem} onClick={() => clear()}>
            {t("SideNavigation.Button.Clear")}
          </MenuItem>

          {sortedArray.map((value) => {
            let val: FieldOpt | undefined = values.find(
              (q) => q.qText === value
            );
            if (val)
              return (
                <MenuItem
                  className={`${classes.menuItem} ${menuItemStyle(
                    val ? (val as FieldOpt).qState : "O"
                  )}`}
                  key={value}
                  value={
                    val
                      ? !isNaN((val as FieldOpt).qNum)
                        ? (val as FieldOpt).qNum
                        : (val as FieldOpt).qText
                      : value
                  }
                  onClick={() => handleChange(val as FieldOpt)}
                >
                  {value}
                </MenuItem>
              );
            else return null;
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default MultiSelectComponent;
