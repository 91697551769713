import { Box } from "@mui/material";
import { useState } from "react";
import { useQlikAppContext } from "../../context/QlikAppContext";
import { KPIMeter } from "../../types/componentTypes/KPIMeter";
import KPIMeterComponent from "../KPIMeterComponent/KPIMeterComponent";
import { useKpiContainerStyles } from "./KpiContainer.styles";

type KpiContainerProps = {
  kpiMeters: KPIMeter[];
  kpiVariableName: string;
  styleClass?: string;
};

const KpiContainer = (props: KpiContainerProps) => {
  const { kpiMeters, kpiVariableName, styleClass } = props;
  const classes = useKpiContainerStyles();
  const [currentKpi, setCurrentKpi] = useState(1);
  const { app } = useQlikAppContext();

  const handleKPIChange = async (value: number) => {
    setCurrentKpi(value);
    await app.variable.setNumValue(kpiVariableName, value);
  };

  return (
    <Box className={`${classes.root} ${styleClass}`}>
      {kpiMeters.map((meter: KPIMeter) => (
        <KPIMeterComponent
          selected={currentKpi === meter.kpiValue}
          key={meter.kpiId}
          title={meter.title}
          kpiId={meter.kpiId}
          comparingKpiId={meter.comparingKpiId}
          isPercent={meter.isPercent}
          isInteger={meter.isInteger}
          onClick={() => handleKPIChange(meter.kpiValue)}
          oneDecimal={meter.oneDecimal ?? undefined}
        />
      ))}
    </Box>
  );
};

export default KpiContainer;
