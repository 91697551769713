import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography
} from "@mui/material";
import { Icon } from "@terveystalo/design-system-react-components";
import { useAccordionComponentStyles } from "./AccordionComponent.styles";
import AccordionItem from "./parts/AccordionItem";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";

const AccordionComponent = () => {
  const classes = useAccordionComponentStyles();
  const { t } = useTranslation();
  const [expanded, toggleExpanded] = useState(true);

  return (
    <Box>
      <Accordion
        expanded={expanded}
        onChange={() => toggleExpanded(!expanded)}
        disableGutters
        square
        className={classes.root}
      >
        <AccordionSummary
          expandIcon={<Icon name={expanded ? "Minus" : "Plus"} />}
          className={classes.accordionTitle}
        >
          <Typography variant="h6" color="primary.dark">
            {t("Report")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionDetails}>
          <AccordionItem
            iconName="Doctor"
            label={t("VisitAndRegistration")}
            href="/visitAndRegistration"
          />
          <AccordionItem
            iconName="ExternalLink"
            label={t("SickLeaves")}
            href="/sickLeaves"
          />
          <AccordionItem
            iconName="Euro"
            label={t("Expenses")}
            href="/expense"
          />
          <AccordionItem
            iconName="Activity"
            label={t("WorkAbilityRisk")}
            href="/workAbilityRisk"
          />
          <AccordionItem
            iconName="BarChart"
            label={t("Parameters")}
            href="/kpi"
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default AccordionComponent;
// Book Download will be used