import { Box } from "@mui/system";
import { useKpiPageStyles } from "./KpiPage.styles";
import CustomTable, {
  LibraryLabelId
} from "../../components/CustomTable/CustomTable";

type KpiPageProps = {
  styleClass?: string;
};

const phenomenomTableDimensions: LibraryLabelId[] = [
  {
    name: "Jaottelu",
    qLabel: "Jaottelu",
    qLibraryId: "Jaottelu",
    objectType: "field"
  },
  {
    name: "Avainmittari",
    qLabel: "Tunnusluvut Avainmittari",
    qLibraryId: "Uxjnhk",
    objectType: "dimension"
  }
];

const phenomenomTableMeasures: LibraryLabelId[] = [
  {
    name: "Liian pieni otos",
    qLabel: "Tunnusluvut liian pieni otos",
    qLibraryId: "JYEkGM",
    objectType: "measure"
  },
  {
    name: "Valittu aika",
    qLabel: "Tunnusluvut Valittu aika",
    qLibraryId: "NAkxJqf",
    objectType: "measure"
  },
  {
    name: "Vertailu aika",
    qLabel: "Tunnusluvut Vertailu aika",
    qLibraryId: "TGjQmY",
    objectType: "measure"
  }
];

const KpiPage = (props: KpiPageProps) => {
  const classes = useKpiPageStyles();
  const { styleClass } = props;
  return (
    <Box className={`${classes.root} ${styleClass}`}>
      <Box className={classes.contentContainer}>
        <CustomTable
          dimensions={phenomenomTableDimensions}
          measures={phenomenomTableMeasures}
        />
      </Box>
    </Box>
  );
};

export default KpiPage;
