import { Theme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

export const useHeaderStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      gridArea: "header"
    },
    mainHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.dark,
      minHeight: "1.5rem"
    },
    navBarContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: "#fff",
      minHeight: "3.5rem",
      borderBottom: `1px solid #e6f5fb`
    },
    menuButton: {
      height: "5rem",
      minWidth: "5rem",
      backgroundColor: "transparent",
      borderRight: "1px solid #e6f5fb",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      cursor: "pointer",
      "&:hover": {
        transition: "all .15s ease-in-out",
        backgroundColor: `${theme.palette.primary.light} !important`
      }
    },
    logo: {
      marginLeft: "2rem",
      cursor: "pointer",
      marginRight: "1rem"
    },
    headerLink: {
      padding: "0.5rem 0",
      textDecoration: "none",
      paddingLeft: "1.5rem",
      "&:hover": {
        color: "#001d47 !important"
      },
      [theme.breakpoints.down("md")]: {
        display: "none"
      }
    },
    activeLink: {
      textUnderlinePosition: "under",
      textDecorationLine: "underline",
      textUnderlineOffset: "3px"
    },
    userMenuContainer: {
      marginLeft: "auto",
      backgroundColor: `${(theme.palette.primary as any).darker} !important`,
      height: "5rem",
      minWidth: "5rem",
      display: "flex",
      alignItems: "center"
    },
    userMenuButton: {
      color: "white !important",
      height: "5rem",
      padding: "1rem 1.5rem !important"
    },
    userName: {
      marginLeft: "1rem",
      [theme.breakpoints.down("md")]: {
        display: "none"
      }
    }
  })
);
