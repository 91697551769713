import { Box, Modal, Typography } from "@mui/material";
import { Icon } from "@terveystalo/design-system-react-components";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AbsenceDisplay from "./AbsenceDisplay";
import AbsenceStats from "./AbsenceStats";
import AbsenceTimespan from "./AbsenceTimespan";
import { useAbsenceComponentStyles } from "./AbsenseComponent.styles";

const AbsenseComponent = () => {
  const classes = useAbsenceComponentStyles();
  const { t } = useTranslation();
  const [isFullscreen, setFullscreen] = useState(false);

  const absence = (
    <Box className={classes.root}>
      <Box className={classes.titleContainer}>
        <Typography variant="h6">
          {t("WorkAbilityRiskPage.AbsenceTable.Title")}
        </Typography>
        <Box className={classes.iconContainer}>
          <Icon
            name={isFullscreen ? "Close" : "ExternalLink"}
            className={classes.iconButton}
            onClick={() => setFullscreen(!isFullscreen)}
          />
        </Box>
      </Box>
      <Box overflow="auto">
        <Box className={classes.displayRow}>
          <AbsenceTimespan
            title={t("WorkAbilityRiskPage.AbsenceTable.ChosenTimespan")}
            QAP_id="QAP_Riski_ryhmittely_otsikko"
          />
          <AbsenceDisplay
            title="0 - 9 päivää"
            QAP_id="QAP_Riski_poissaolopäivät_0_9"
            QAP_osuus_id="QAP_Riski_poissaolopäivät_0_9_osuus"
          />

          <AbsenceDisplay
            title="10 - 29 päivää"
            QAP_id="QAP_Riski_poissaolopäivät_10_29"
            QAP_osuus_id="QAP_Riski_poissaolopäivät_10_29_osuus"
          />

          <AbsenceDisplay
            title="30 - 59 päivää"
            QAP_id="QAP_Riski_poissaolopäivät_30_59"
            QAP_osuus_id="QAP_Riski_poissaolopäivät_30_59_osuus"
          />

          <AbsenceDisplay
            title="60 - 89 päivää"
            QAP_id="QAP_Riski_poissaolopäivät_60_89"
            QAP_osuus_id="QAP_Riski_poissaolopäivät_60_89_osuus"
          />

          <AbsenceDisplay
            title="Yli 90 päivää"
            QAP_id="QAP_Riski_poissaolopäivät_yli_90"
            QAP_osuus_id="QAP_Riski_poissaolopäivät_yli_90_osuus"
          />
        </Box>
        <Box className={classes.displayRow}>
          <AbsenceTimespan
            title={t("WorkAbilityRiskPage.AbsenceTable.ComparisonTimespan")}
            QAP_id="QAP_Riski_ryhmittely_otsikko_EDV"
          />

          <AbsenceDisplay
            title="0 - 9 päivää"
            QAP_id="QAP_Riski_poissaolopäivät_0_9_EDV"
            QAP_osuus_id="QAP_Riski_poissaolopäivät_0_9_osuus_EDV"
          />

          <AbsenceDisplay
            title="10 - 29 päivää"
            QAP_id="QAP_Riski_poissaolopäivät_10_29_EDV"
            QAP_osuus_id="QAP_Riski_poissaolopäivät_10_29_osuus_EDV"
          />

          <AbsenceDisplay
            title="30 - 59 päivää"
            QAP_id="QAP_Riski_poissaolopäivät_30_59_EDV"
            QAP_osuus_id="QAP_Riski_poissaolopäivät_30_59_osuus_EDV"
          />

          <AbsenceDisplay
            title="60 - 89 päivää"
            QAP_id="QAP_Riski_poissaolopäivät_60_89_EDV"
            QAP_osuus_id="QAP_Riski_poissaolopäivät_60_89_osuus_EDV"
          />

          <AbsenceDisplay
            title="Yli 90 päivää"
            QAP_id="QAP_Riski_poissaolopäivät_yli_90_EDV"
            QAP_osuus_id="QAP_Riski_poissaolopäivät_yli_90_osuus_EDV"
          />
        </Box>
        <Box className={classes.displayRow}>
          <Box className={classes.dataDisplay} bgcolor="#F6F6F6">
            <Typography variant="body2">
              {t("WorkAbilityRiskPage.AbsenceTable.Increased")}
            </Typography>
            <Typography variant="body2">
              {t("WorkAbilityRiskPage.AbsenceTable.Decreased")}
            </Typography>
            <Typography variant="body2">
              {t("WorkAbilityRiskPage.AbsenceTable.Remained")}
            </Typography>
          </Box>
          <AbsenceStats
            positiveMeasureName="QAP_Riski_riskikategoria_kasvaneet_0_9"
            negativeMeasureName="QAP_Riski_riskikategoria_vähentyneet_0_9"
            neutralMeasureName="QAP_Riski_riskikategoria_pysyneet_0_9"
          />
          <AbsenceStats
            positiveMeasureName="QAP_Riski_riskikategoria_kasvaneet_10_29"
            negativeMeasureName="QAP_Riski_riskikategoria_vähentyneet_10_29"
            neutralMeasureName="QAP_Riski_riskikategoria_pysyneet_10_29"
          />
          <AbsenceStats
            positiveMeasureName="QAP_Riski_riskikategoria_kasvaneet_30_59"
            negativeMeasureName="QAP_Riski_riskikategoria_vähentyneet_30_59"
            neutralMeasureName="QAP_Riski_riskikategoria_pysyneet_30_59"
          />
          <AbsenceStats
            positiveMeasureName="QAP_Riski_riskikategoria_kasvaneet_60_89"
            negativeMeasureName="QAP_Riski_riskikategoria_vähentyneet_60_89"
            neutralMeasureName="QAP_Riski_riskikategoria_pysyneet_60_89"
          />
          <AbsenceStats
            positiveMeasureName="QAP_Riski_riskikategoria_kasvaneet_yli_90"
            negativeMeasureName="QAP_Riski_riskikategoria_vähentyneet_yli_90"
            neutralMeasureName="QAP_Riski_riskikategoria_pysyneet_yli_90"
          />
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      {isFullscreen ? (
        <Modal open={isFullscreen} onClose={() => setFullscreen(false)}>
          <Box className={classes.modalContainer}>{absence}</Box>
        </Modal>
      ) : (
        <Box>{absence}</Box>
      )}
    </>
  );
};

export default AbsenseComponent;
