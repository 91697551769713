import { MultiSelectProps } from "./MultiSelectComponent";
import { useReducer, useCallback, useEffect } from "react";
import reducer from "./multiSelectReducer/ReducerFunctions";
import reducerDefaultValues from "./multiSelectReducer/ReducerDefault";
import { useMultiSelectComponentStyles } from "./MultiSelectComponent.styles";
import {
  ContextActionEnum,
  ContextStateVariables
} from "./multiSelectReducer/ReducerIntEnums";
import { QValue } from "../../types/componentTypes/Chart";
import { useQlikAppContext } from "../../context/QlikAppContext";
import { createMultiSelectValuesList } from "../../utils/qlikUtils";
import { QlikStates } from "../../types/qlikStates";

export const Defaults = {
  MonthYear: ContextStateVariables.MonthYearDefaults,
  VuosiKvartaali: ContextStateVariables.QuarterDefaults
};

export const useMultiSelectComponent = (props: MultiSelectProps) => {
  const { hasqNum, field, restCounter, defaultValues, pathVar } = props;
  const { app, qlikAppLoading, setUpdateGraph } = useQlikAppContext();
  const [state, dispatch] = useReducer(reducer, reducerDefaultValues(hasqNum));
  const {
    sortedArray,
    values,
    open,
    selections,
    monthYearDefaults,
    selectedDisplay
  } = state;

  const classes = useMultiSelectComponentStyles();

  const clear = () => dispatch({ type: ContextActionEnum.Clear });
  const handleChange = (value: QValue) =>
    dispatch({ type: ContextActionEnum.HandleChange, playload: { value } });

  const createListObject = useCallback(() => {
    const lb = createMultiSelectValuesList(field);
    app.createList(lb as any, (r) => {
      const mat = r.qListObject.qDataPages[0].qMatrix;
      let mappedMat = mat.map((i: any) => i[0]);
      dispatch({
        type: ContextActionEnum.ChangeVariable,
        playload: {
          value: mappedMat,
          variable: ContextStateVariables.Values
        }
      });
    });
  }, [app, field]);

  const menuItemStyle = (state: string) => {
    if (state === QlikStates.SELECTED_STATE) return classes.selectedState;
    if (
      state === QlikStates.EXCLUDED_SELECTED_STATE ||
      state === QlikStates.ALTERNATIVE_STATE
    )
      return classes.alternativeState;
    if (state === QlikStates.EXCLUDED_STATE) return classes.excludedState;

    return "";
  };

  const setOpen = (value: boolean) =>
    dispatch({
      type: ContextActionEnum.ChangeVariable,
      playload: { variable: ContextStateVariables.Open, value }
    });

  const reset = (type: string) =>
    dispatch({
      type: ContextActionEnum.Reset,
      playload: { value: { field, restType: type } }
    });

  ////

  useEffect(() => {
    if (app && !qlikAppLoading) createListObject();
  }, [app, createListObject, qlikAppLoading]);

  ///
  useEffect(() => {
    reset("One");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app, qlikAppLoading, restCounter.counterOne, field]);

  useEffect(() => {
    reset("Two");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app, qlikAppLoading, restCounter.counterTwo, field]);
  useEffect(() => {
    if (app && !qlikAppLoading) {
      app.field(field).selectValues(selections, false, false);
      setUpdateGraph();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selections]);

  // Can be changed later
  useEffect(() => {
    if ((sortedArray.length === 0 && values.length !== 0) || !open) {
      dispatch({
        type: ContextActionEnum.ChangeVariable,
        playload: {
          variable: ContextStateVariables.SortedArray,
          value: [...values].map((v) => v.qText)
        }
      });
    }
    if (values) {
      dispatch({
        type: ContextActionEnum.ChangeVariable,
        playload: {
          variable: ContextStateVariables.SelectedDisplay,
          value: values.filter((v) => v.qState === "S").map((v) => v.qText)
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, open]);

  useEffect(() => {
    if (
      (field === "MonthYear" || field === "VuosiKvartaali") &&
      defaultValues
    ) {
      dispatch({
        type: ContextActionEnum.ChangeVariable,
        playload: {
          variable: Defaults[field],
          value: defaultValues
        }
      });
    }
  }, [defaultValues]);

  /**
  useEffect(() => {
    const waitSeconds =
      field === "VuosiKvartaali" && pathVar === "/workAbilityRisk"
        ? false
        : true;
    if (pathVar) {
      if (sortedArray.length !== 0 && waitSeconds) {

      console.log("Is apply");
      setTimeout(() => {
          app.field(field).selectValues(selections);
          setUpdateGraph();
        
      //  else reset("One");
      }, 2000); 
    } else {
      setTimeout(() => {
       // reset("One");
      },1900)
    }
    }
  }, [pathVar]); **/

  useEffect(() => {
    if (pathVar) {
      if (sortedArray.length !== 0 ) {
      setTimeout(() => {
        app
          .field(field)
          .selectValues(selections, false, false)
          .then(() => {
            setUpdateGraph();
            reset("One");
          });

        //setUpdateGraph();
        //
      }, 4000);

         }
    }
  }, [pathVar]);

  return {
    classes,
    open,
    selectedDisplay,
    sortedArray,
    selections,
    values,
    handleChange,
    setOpen,
    menuItemStyle,
    clear
  };
};

export default useMultiSelectComponent;
