import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationFI from "./locales/fi/translation.json";
import translationEN from "./locales/en/translation.json";

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  fi: {
    translation: translationFI
  }
};

const detectionOptions = {
  order: ["navigator"],
  lookupFromPathIndex: 0
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: "fi",
    fallbackLng: "en",
    detection: detectionOptions,
    debug: true,
    interpolation: { escapeValue: false }
  });

export default i18n;
