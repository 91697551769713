import { createStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useInputComponentStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      borderBottom: `1px solid ${theme.palette.primary.light}`,
      padding: "1rem 1.5rem 0.5rem 1.5rem"
    },
    text: {
      marginRight: "15px"
    }
  })
);
