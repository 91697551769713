import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { usePageTitleComponentStyles } from "./PageTitleComponent.styles";

type PageTitleComponentProps = {
  styleClass?: string;
};

const PageTitleComponent = (props: PageTitleComponentProps) => {
  const { styleClass } = props;
  const { t } = useTranslation();
  const classes = usePageTitleComponentStyles();

  return (
    <Box className={`${classes.root} ${styleClass}`}>
      <Typography variant="h4" color="#fff">
        {t("KPIPage.Title.OccupationalHealthKPI")}
      </Typography>
    </Box>
  );
};

export default PageTitleComponent;
