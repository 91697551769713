import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useKPIMeterComponentStyles } from "./KPIMeterComponent.styles";
import { useQlikAppContext } from "../../context/QlikAppContext";
import { calcDifference, convertToNumAbbr, formatNumber } from "../../utils/utilFunctions";
import { createHyperCubeDef } from "../../utils/qlikUtils";

type KPIMeterComponentProps = {
  selected?: boolean;
  title: string;
  kpiId: string;
  comparingKpiId: string;
  isPercent?: boolean;
  isInteger?: boolean;
  onClick?: () => void;
  oneDecimal?: boolean;
};

const KPIMeterComponent = (props: KPIMeterComponentProps) => {
  const {
    selected,
    kpiId,
    comparingKpiId,
    title,
    isPercent,
    isInteger,
    oneDecimal,
    onClick
  } = props;
  const classes = useKPIMeterComponentStyles(selected);
  const { app, qlikAppLoading, } = useQlikAppContext();
  const [kpiData, setKpiData] = useState(0);
  const [activeDisplay, setActiveDisplay] = useState<boolean>(true);
  const [comparingKpiData, setComparingKpiData] = useState(0);
  const [differencePercent, setDifferencePercent] = useState("");

  useEffect(() => {
    if (app && !qlikAppLoading) {
      const hcd = createHyperCubeDef([kpiId, comparingKpiId]);
      app.createCube(hcd, (hc) => {
        const matrix = hc.qHyperCube.qDataPages[0].qMatrix[0];
        if (matrix[0].qIsNull || (matrix[0].qText === '-')) {
          setActiveDisplay(false);
        }
        else setActiveDisplay(true);
        // 
        setDifferencePercent(calcDifference(matrix[1].qNum, matrix[0].qNum, oneDecimal));
        setKpiData(isPercent ? matrix[0].qNum * 100 : matrix[0].qNum);
        setComparingKpiData(isPercent ? matrix[1].qNum * 100 : matrix[1].qNum);
      });
    }
    return () => {};
  }, [app, comparingKpiId, isPercent, kpiId, qlikAppLoading]);

  if (activeDisplay)
    return (
      <Box className={classes.root} onClick={onClick}>
        <Typography color={selected ? "" : "#A4CBE1"} variant="body2">
          {title}
        </Typography>
        <Typography color={selected ? "" : "#fff"} variant="h4">
          {formatNumber(kpiData, oneDecimal) ?? "0"}
          {isPercent && " %"}
        </Typography>
        <Box className={classes.changesContainer}>
          <Typography color={selected ? "" : "#A4CBE1"} variant="body2">
           {formatNumber(comparingKpiData, oneDecimal) ?? "0"} 
            {isPercent && " %"}
          </Typography>
          <Typography color={selected ? "primary" : "#fff"} variant="body2">
            {differencePercent ?? "-"}
          </Typography>
        </Box>
      </Box>
    );
   else return null;
};

export default KPIMeterComponent;
