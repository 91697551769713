import { Box } from "@mui/system";
import ChartComponent from "../../components/ChartComponent/ChartComponent";
import { Chart } from "../../types/componentTypes/Chart";
import { visitAndRegistrationPageCharts } from "../../utils/visitAndRegistrationPage.service";
import { useVisitAndRegistrationPageStyles } from "./VisitAndRegistrationPage.styles";

type VisitAndRegistrationPageProps = {
  styleClass?: string;
};

const VisitAndRegistrationPage = (props: VisitAndRegistrationPageProps) => {
  const classes = useVisitAndRegistrationPageStyles();
  const { styleClass } = props;
  return (
    <Box className={`${classes.root} ${styleClass}`}>
      <Box className={classes.contentContainer}>
        {visitAndRegistrationPageCharts.map((chart: Chart) => (
          <ChartComponent
            key={chart.objectId}
            title={chart.title}
            dimensionName={chart.dimensionName}
            dimensionSelectionIds={chart.dimensionSelectionIds}
            objectId={chart.objectId}
            noSelections={chart.noSelections}
          />
        ))}
      </Box>
    </Box>
  );
};

export default VisitAndRegistrationPage;
