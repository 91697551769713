import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useQlikAppContext } from "../../context/QlikAppContext";
import { createHyperCubeDef } from "../../utils/qlikUtils";

import { useAbsenceComponentStyles } from "./AbsenseComponent.styles";

type AbsenceTimespanProps = {
  title: string;
  QAP_id: string;
};

const AbsenceTimespan = (props: AbsenceTimespanProps) => {
  const { title, QAP_id } = props;

  const [qap, setQap] = useState("");
  const classes = useAbsenceComponentStyles();
  const { app, qlikAppLoading } = useQlikAppContext();

  useEffect(() => {
    if (app && !qlikAppLoading) {
      const hcd = createHyperCubeDef([QAP_id]);

      app.createCube(hcd, (hc) => {
        const matrix = hc.qHyperCube.qDataPages[0].qMatrix[0];
        console.log('Matrix is:');
        console.log(matrix);
        // Remove "Valittu aika:"
        // 
        setQap(matrix[0].qText.toString().split(":")[1]);
      });
    }
    return () => {};
  }, [QAP_id, app, qlikAppLoading]);

  return (
    <Box className={classes.dataDisplay} bgcolor="#F6F6F6">
      <Typography variant="body2">{title}</Typography>
      <Typography variant="body2" color="#000">
        {qap}
      </Typography>
    </Box>
  );
};

export default AbsenceTimespan;
