import {
  ContextActionEnum,
  ContextActionProps,
  ContextStateProps
} from "./ReducerIntEnums";
import { QValue } from "../../../types/componentTypes/Chart";
import { QlikApp } from "../../../types/qlik-types";
import { createMultiSelectValuesList } from "../../../utils/qlikUtils";
import { Defaults } from "../UseMultiSelectComponent";

const enum BookmarkDefaultB {
  MonthYear = "MonthYear",
  VuosiKvartaali = "VuosiKvartaali"
}

const reducer = (
  state: ContextStateProps,
  action: ContextActionProps
): ContextStateProps => {
  switch (action.type) {
    case ContextActionEnum.ChangeVariable: {
      if (action.playload) {
        if (action.playload.variable)
          return {
            ...state,
            [action.playload.variable]: action.playload.value
          };
        return state;
      }
      return state;
    }
    case ContextActionEnum.Clear: {
      return { ...state, selections: [], selectedDisplay: [] };
    }
    case ContextActionEnum.HandleChange: {
      if (action.playload) {
        const { selections, hashNum } = state;
        const value: QValue = action.playload.value;
        const newEffValue = hashNum ? value.qNum : value.qText;
        const valueFound = selections.find((v) => v === newEffValue);
        if (valueFound) {
          const newFiltered = selections.filter((v) => v !== newEffValue);
          return { ...state, selections: newFiltered };
        }
        return { ...state, selections: [...selections, newEffValue] };
      }
      return state;
    }

    case ContextActionEnum.Reset: {
      if (action.playload) {
        const { field, restType } = action.playload.value;
        const {  hashNum} = state;
        if (field === "MonthYear" || field === "VuosiKvartaali") {
          console.log("We are in field");
          console.log(field);
          let defaultsAll : any = state[Defaults[field as BookmarkDefaultB]]
          console.log(defaultsAll);
          if (defaultsAll.length !== 0) {
            const selections = defaultsAll
              .filter((v : any) => v.qState === "S")
              .map((v : any) => hashNum ? v.qNum : v.qText);
            return { ...state, selections };
          }
        } 
        else {
          if (restType === "One") {
            switch (field) {
              case "Month":
              case "Year":
                return { ...state, selections: [] };
            }
          } else return { ...state, selections: [] };
        }
        return state;
      }
      return state;
    }
    default:
      return state;
  }
};

export default reducer;
