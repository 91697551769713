export enum ContextActionEnum {
    ChangeVariable = "ChangeVariable",
    SetTokenUsername = "SetTokenUsername",
    UpdateGraphOP = "UpdateGraphOP"

}

export enum ContextStateVariables {
 Username = "username",
 UpdateGraph = "updateGraph",
 Token = "token"

}

export interface ContextStateProps {
  username: string;
  updateGraph: number;
  token: string;
}

export interface ContextActionProps {
  type: ContextActionEnum;
  playload?: {
    variable?: ContextStateVariables;
    value: any;
  };
}
