/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Skeleton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Icon } from "@terveystalo/design-system-react-components";
import { useCallback, useEffect, useState } from "react";
import { useQlikAppContext } from "../../context/QlikAppContext";
import { hasWhiteSpace } from "../../utils/qlikUtils";
import { camelCaseToCapitalizedText } from "../../utils/utilFunctions";
import { useChartComponentStyles } from "./ChartComponent.styles";

type ChartComponentProps = {
  title: string | string[];
  objectId: string;
  dimensionSelectionIds?: string[];
  dimensionName?: string;
  noSelections?: boolean;
  fillHeight?: boolean;
};

const ChartComponent = (props: ChartComponentProps) => {
  const {
    title,
    objectId,
    noSelections,
    dimensionSelectionIds,
    dimensionName,
    fillHeight
  } = props;
  const { app, qlikAppLoading, updateGraph } = useQlikAppContext();
  const [isFullscreen, setFullscreen] = useState(false);
  const [showChart, setShowChart] = useState<boolean>(true);

  const [selectedDimension, setSelection] = useState(
    dimensionSelectionIds && dimensionSelectionIds[2]
  );
  const classes = useChartComponentStyles(showChart);

  const initSelection = useCallback(async () => {
   
    if (dimensionName) {
      await app.variable.getContent(dimensionName, (res) => {

         setSelection(res.qContent.qString);
      });
    }
  }, [app, dimensionName]);

  const toggleSelection = useCallback(
    async (newVal: any) => {
      if (dimensionName) {
        let newValValidate = hasWhiteSpace(newVal as string) ? '[' + newVal + ']' : newVal;
        await app.variable.setStringValue(dimensionName, newValValidate);
        await initSelection();
      }
    },
    [app, dimensionName, initSelection]
  );

  const exportChartData = () => {
  
    app.visualization.get(objectId).then(function (vis) {
      vis
        .exportData({ format: "OOXML", state: "A" })
        .then(function (link: any) {
          window.open(link);
        });
    });
  };

  const exportChartImage = () => {
    app.visualization.get(objectId).then(function (vis) {
      vis
        .exportImg({ format: "PNG", width: 1920, height: 1080 })
        .then(function (link: any) {
          window.open(link);
        });
    });
  };

  useEffect(() => {
    if (app && !qlikAppLoading) {
      setTimeout(() => {
        app.getObject(objectId).then((cal) => {
          let isDisabled =
            cal.layout.qHyperCube.qGrandTotalRow[0].qText === "-";
          setShowChart(isDisabled ? false : true);
        });
      }, 200);
    }
  }, [updateGraph]);

  useEffect(() => {
    (async () => {
      if (app && !qlikAppLoading) {
        await initSelection();
        app
          .getObject(`chartContainer-${objectId}`, objectId, {
            noSelections: true
          })
          .then((cal) => {
            let isDisabled =
              cal.layout.qHyperCube.qGrandTotalRow[0].qText === "-";
            console.log(cal.layout.qHyperCube);
            setShowChart(isDisabled ? false : true);
          });
      }
    })();
  }, [
    app,
    initSelection,
    noSelections,
    objectId,
    qlikAppLoading,
    isFullscreen
  ]);

  const charts = qlikAppLoading ? (
    <>
      <Box className={classes.titleContainer}>
        <Skeleton
          animation="wave"
          variant="rectangular"
          width="100%"
          height="2rem"
        ></Skeleton>
      </Box>
      <Box className={classes.chartContainer}>
        <Skeleton
          animation="wave"
          variant="rectangular"
          width="100%"
          height="30rem"
        />
      </Box>
    </>
  ) : (
    <>
      <Box className={classes.titleContainer}>
        <Typography variant="h6">{title}</Typography>
        <Box className={classes.iconContainer}>
          <Icon
            name="Image"
            className={classes.iconButton}
            onClick={exportChartImage}
          />
          <Icon
            name="Document"
            className={classes.iconButton}
            onClick={exportChartData}
          />
          <Icon
            name={isFullscreen ? "Close" : "ExternalLink"}
            className={classes.iconButton}
            onClick={() => setFullscreen(!isFullscreen)}
          />
        </Box>
      </Box>

      <Box className={classes.chartContainer}>
        <Box
          id={`chartContainer-${objectId}`}
          width="100%"
          height={isFullscreen ? "95%" : fillHeight ? "65vh" : "30rem"}
        />
      </Box>
      {dimensionSelectionIds && (
        <Box className={classes.multiChartSelection}>
          {dimensionSelectionIds.map((id: string) => (
            <Typography
              className={`${classes.multiChartLink} ${
                id === selectedDimension ? classes.activeLink : ""
              }`}
              key={id}
              variant="h6"
              onClick={() => toggleSelection(id)}
            >
              {camelCaseToCapitalizedText(id)}
            </Typography>
          ))}
        </Box>
      )}
    </>
  );

  return (
    <Box className={classes.allBlock}>
      {isFullscreen ? (
        <Modal open={isFullscreen} onClose={() => setFullscreen(false)}>
          <Box className={classes.modalContainer}>{charts}</Box>
        </Modal>
      ) : (
        <Box className={classes.root}>{charts}</Box>
      )}
    </Box>
  );
};

export default ChartComponent;
