import { createStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useMultiSelectComponentStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    formControl: {
      width: "100%"
    },
    selectInput: {
      minHeight: "3.5rem",
      "&:focus": {
        backgroundColor: "transparent"
      }
    },
    selected: {
      backgroundColor: theme.palette.primary.light
    },
    selectIcon: {
      paddingRight: "2rem",
      cursor: "pointer"
    },
    menuItem: {
      padding: "1rem 1rem 1rem 1.5rem !important",
      "&:hover": {
        filter: "saturate(1.2)"
      }
    },
    selectedState: {
      backgroundColor: "#d9e3f3 !important",
      color: "#0063f6 !important"
    },
    alternativeState: {
      backgroundColor: "#ddd !important",
      color: "#000 !important"
    },
    excludedState: {
      backgroundColor: "#b3b3b3 !important",
      color: "#fff !important",
      fontStyle: "italic !important"
    }
  })
);
