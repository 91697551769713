import { createStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const usePageTitleComponentStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      padding: "4rem 2rem",
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      overflow: "auto",
      backgroundColor: theme.palette.primary.darker,
      [theme.breakpoints.down("md")]: {
        padding: "2rem"
      }
    }
  })
);
