import { Box, Button, Typography } from "@mui/material";
import { useHeaderStyles } from "./Header.styles";
import { Icon, Logo } from "@terveystalo/design-system-react-components";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import SideMenuComponent from "../SideMenuComponent/SideMenuComponent";
import { useQlikAppContext } from "../../context/QlikAppContext";

const routes = [
  { href: "/visitAndRegistration", name: "VisitAndRegistration" },
  { href: "/sickLeaves", name: "SickLeaves" },
  { href: "/expense", name: "Expenses" },
  { href: "/workAbilityRisk", name: "WorkAbilityRisk" },
  { href: "/kpi", name: "Parameters" }
];

type HeaderProps = {
  styleClass?: string;
};

const Header = (props: HeaderProps) => {
  const { styleClass } = props;
 // const [username, setUsername] = useState<string>('Username');
  const classes = useHeaderStyles();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [sideMenuOpen, toggleSideMenuOpen] = useState(false);
  const { app, username } = useQlikAppContext();

  const handleToggleSideMenu = () => {
    toggleSideMenuOpen(!sideMenuOpen);
  };
  
  /** 
  useEffect(() => {
    console.log("Test username");
    axios.get(`https://${environment.qlikConfig.host}${environment.qlikConfig.prefix}qps/user`)
    .then(model => {
      console.log(model.data);
    }) 
 
 
  }, [app, qlikAppLoading])  **/




  return (
    <Box className={styleClass}>
      <Box className={`${classes.root}`}>
        <Box className={classes.mainHeader}>
          <Typography variant="subtitle2">
            {t("MainTitle").toUpperCase()}
          </Typography>
        </Box>
        <Box className={classes.navBarContainer}>
          <Box className={classes.menuButton} onClick={handleToggleSideMenu}>
            <Icon width="1.5rem" height="1.5rem" name="Navigation" />
            <Typography variant="button" mt="0.25rem">
              {t("Menu")}
            </Typography>
          </Box>
          <Link to="/">
            <Logo className={classes.logo} name="TerveystaloNew" />
          </Link>

          {routes.map((route: any) => (
            <Link
              key={route.name}
              className={classes.headerLink}
              to={route.href}
            >
              <Typography
                className={pathname === route.href ? classes.activeLink : ""}
                variant="button"
              >
                {t(route.name)}
              </Typography>
            </Link>
          ))}
          <Box className={classes.userMenuContainer}>
            <Button
              className={classes.userMenuButton}
              startIcon={<Icon color="white" name="Profile" />}
            >
              <Typography
                className={classes.userName}
                variant="button"
                color="white"
              >
                {username}
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>

      {sideMenuOpen && (
        <SideMenuComponent
          menuOpen={sideMenuOpen}
          toggleMenu={toggleSideMenuOpen}
        />
      )}
    </Box>
  );
};

export default Header;
