import { HyperCubeDef, NxMeasure } from "../types/qlik-types";

export const createHyperCubeDef = (idList: string[]) => {
  let measures = idList.map((id: string) => {
    return {
      qLabel: id,
      qAttributeDimensions: [],
      qAttributeExpressions: [],
      qLibraryId: "",
      qDef: { qDef: id },
      qCalcCond: undefined,
      qSortBy: undefined
    } as NxMeasure;
  });

  let hcd: HyperCubeDef = {
    qMeasures: measures,
    qInitialDataFetch: [{ qWidth: 20, qHeight: 5 }]
  };

  return hcd;
};


export const createMultiSelectValuesList = (
  field: string,
  sortCriteria?: any
) => {
  const listObject = {
    qFrequencyMode: "V",
    qShowAlternatives: true,
    qDef: {
      qFieldDefs: [field],
      qSortCriterias: sortCriteria
        ? [sortCriteria]
        : [{ qSortByState: 1, qSortByqSortByAscii: 1 }],
    },
    qInitialDataFetch: [
      {
        qHeight: 500,
        qWidth: 10
      },
    ],
  };

  return listObject;
};


export const hasWhiteSpace = (s: string) => {
  return s.indexOf(' ') >= 0;
}
