import { createTheme } from "@mui/material/styles";

const palette = {
  primary: {
    dark: "#0046AD",
    main: "#0063F6",
    light: "#E6F5FB",
    contrastText: "#212322",
    darker: "#1A244A"
  },
  secondary: {
    dark: "#6F706F",
    light: "#F6F6F6",
    main: "#CECFCE"
  },
  success: { main: "#00773E" },
  error: { main: "#DA0900" }
};

export const theme = createTheme({
  palette,
  components: {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: palette.primary.dark,
          backgroundColor: "#F6F6F6",
          borderRadius: "24px",
          padding: "5px 16px 6px",
          "&.Mui-selected": {
            backgroundColor: palette.primary.dark,
            color: "#fff",
            "&:hover": {
              backgroundColor: palette.primary.main
            }
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          padding: "0 2rem",
          color: palette.primary.dark
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          font: "16px InterFace !important",
          lineHeight: "24px",
          letterSpacing: "0.01rem",
          padding: "1rem 1.5rem"
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          "&:before": {
            borderBottom: `1px solid #D9E3F3`
          }
        },
        input: {
          font: "16px InterFace",
          lineHeight: "24px",
          letterSpacing: "0.01rem",
          padding: "1rem 1.5rem",
          "&:focus": {
            backgroundColor: "transparent"
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          font: "16px InterFace",
          lineHeight: "24px",
          letterSpacing: "0.01rem"
        }
      }
    }
  },
  typography: {
    h1: {
      fontSize: "6rem",
      fontFamily: "Kabel, sans-serif",
      lineHeight: "6.875rem",
      letterSpacing: "-0.015em",
      color: palette.primary.dark
    },
    h2: {},
    h3: {},
    h4: {
      fontSize: "2rem",
      fontFamily: "Kabel, sans-serif",
      fontWeight: 900,
      lineHeight: "38px",
      letterSpacing: "-0.01em",
      color: palette.primary.dark
    },
    h5: {
      fontSize: "30px",
      fontFamily: "InterFace",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "42px",
      color: "#212322"
    },
    h6: {
      fontFamily: "InterFace",
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "25px",
      color: "#212322"
    },
    subtitle1: {
      font: "normal normal normal 16px/24px InterFace",
      letterSpacing: "0.01rem",
      fontFeatureSettings: '"pnum" on, "lnum" on, "ss01" on, "liga" off'
    },
    subtitle2: {
      fontFamily: "InterFace",
      fontWeight: 600,
      fontStyle: "normal",
      fontSize: "16px",
      letterSpacing: "0.01rem",
      lineHeight: "24px",
      fontFeatureSettings: "'pnum' on, 'lnum' on"
    },
    body1: {},
    body2: {
      fontFamily: "InterFace !important",
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 400,
      letterSpacing: "0.01em",
      color: "#6F706F"
    },
    button: {
      fontFamily: "InterFace",
      textTransform: "none",
      color: palette.primary.dark,
      fontSize: "14px !important",
      fontWeight: 400,
      lineHeight: "1.5rem",
      letterSpacing: "0.01rem"
    }
  }
});
