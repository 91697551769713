import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useAbsenceComponentStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#fff",
      display: "flex",
      flexDirection: "column",
      margin: "0 2rem 1.5rem 2rem",
      borderRadius: "0.25rem"
    },
    displayRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      borderTop: "1px solid #CECFCE"
    },
    dataDisplay: {
      display: "flex",
      flexDirection: "column",
      minWidth: "10rem",
      padding: "1.5rem",
      gap: "0.5rem"
    },
    percentText: {
      color: theme.palette.primary.contrastText
    },
    titleContainer: {
      padding: "1rem 1.5rem 1rem",
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    },
    iconContainer: {
      marginLeft: "auto",
      display: "flex",
      flexDirection: "row",
      gap: "2rem"
    },
    iconButton: {
      cursor: "pointer",
      padding: "0.5rem 1.5rem"
    },
    modalContainer: {
      display: "flex",
      flexDirection: "column",
      width: "90%",
      height: "90%",
      backgroundColor: "#fff",
      transform: "translate(5%, 5%)"
    }
  })
);
