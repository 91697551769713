import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import { createStyles } from "@mui/styles";



export const useSessionModalStyles = makeStyles((theme: Theme) => 
    createStyles({
        root: {
            position: "fixed",
            top: '50%',
            left: '50%',
            transform: "translate(-50%, -50%)",
            width: '22rem',
            height: '18rem',
            background: 'white',
            borderRadius: '16px',
            boxShadow:'0px 0px 0px 1px rgba(0, 0, 0, 0.07), 0px 2px 6px rgba(0, 41, 77, 0.07)',
            zIndex:'2',
            padding: '1rem',
            display: (showModal) => (showModal ? "flex" : "none"),
            justifyContent:"center",
            alignItems:"center"

        },
        insideBox: {
            height: "80%",
            width: "90%",
            display:"flex",
            flexDirection:"column",
            justifyContent:"flex-start",
            alignItems:"center"
        },
        button: {
            display:"flex",
            justifyContent:"center",
            alignItems:"center",
            width: "100%",
            position:"relative",
            height:"3.5rem",
            marginTop:"1.5rem"
        },
        buttonText: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: "translate(-50%, -50%)"
        },
        returnLink: {
            width: "100%"
        }
    
    })
)

//color={"white"}  fontSize={"1.5rem"}