import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSessionModalStyles } from "./SessionModal.styles";
import { Button } from "@terveystalo/design-system-react-components";

interface SessionModalProps {
  showModal?: boolean;
}

const SessionModal = (props: SessionModalProps) => {
  const { showModal } = props;
  const classes = useSessionModalStyles(showModal);
  const { t } = useTranslation();
  return (
    <Box className={classes.root}>
      <Box className={classes.insideBox}>
        <Typography variant="h4">{t("SessionEnd.Header")}</Typography>
        <Typography marginTop={"1.5rem"} variant="body2">
          {t("SessionEnd.Text")}
        </Typography>
        <a
          className={classes.returnLink}
          href={process.env.REACT_APP_SUUNTA_LINK}
        >
          <Button className={classes.button}>
            <Typography
              className={classes.buttonText}
              color={"white"}
              fontSize={"1.5rem"}
              variant="h4"
            >
              {t("SessionEnd.Continue")}
            </Typography>
          </Button>{" "}
        </a>
      </Box>
    </Box>
  );
};

export default SessionModal;
