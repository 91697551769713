import { environment } from "../environments/environment";
import  jwt_decode from 'jwt-decode';
import { getToken } from "../utils/utilFunctions";


export default async function getJWT() {
  //const urlParams = new URLSearchParams(window.location.search);
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

 // const token = getToken(window.location.search);
 console.log("Token is:");
 console.log(token);

  const suuntaTokenResponse = await fetch(`${process.env.REACT_APP_JWT_URI}`, {
    method: "POST",
    body: JSON.stringify({
      loginToken: `${token}`
    }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  })
  if (suuntaTokenResponse.ok) {
  // Token from suunta
    const res = await suuntaTokenResponse.json();
    const dataDecoded : any = jwt_decode(res.data.accessToken);
    localStorage.setItem("suunta-username", dataDecoded.user.name);
    localStorage.setItem("current-token", res.data.accessToken);


    const jwtAuthResponse : any = await fetch(
    `https://${environment.qlikConfig.host}${environment.qlikConfig.prefix}resources/assets/external/requirejs/require.js`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Authorization: `Bearer ${res.data.accessToken}`
        }
      }
    ).catch((err) => {
      console.warn("Authorization failed: ", err);
    });
    return jwtAuthResponse;
  } 
  // Conf
  else {
    console.log(
      "Request to suunta failed with error:",
      suuntaTokenResponse.status,
      suuntaTokenResponse.statusText
    );
    return false;
  }
}






 // https://suunta-analytics-test.test.terveystalo.com/?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJTdXVudGEiLCJpYXQiOjE2ODA2OTk1NDIsInVzZXIiOnsiaWQiOjIyMTh9LCJleHAiOjE2ODA2OTk4NDIsImp0aSI6Ijc3YTg5ODFjLTMyNTItNDE5YS1hZTg3LTg1NDlmYTFjZTllNiJ9.Ta4nuFLqq4ur5exyZiJWdIdo9Owu2Es2zCooVu8ABLw#/visitAndRegistration