import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTabComponentStyles } from "./TabComponent.styles";

type TabComponentProps = {
  title: string;
  selected?: boolean;
  onClick: () => void;
};

const TabComponent = (props: TabComponentProps) => {
  const { title, selected, onClick } = props;
  const classes = useTabComponentStyles(selected);

  return (
    <Box className={classes.root} onClick={onClick}>
      <Typography variant="h4" color={selected ? "#0046AD" : "#fff"}>
        {title}
      </Typography>
    </Box>
  );
};

export default TabComponent;
