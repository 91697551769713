import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQlikAppContext } from "../../context/QlikAppContext";
import { createHyperCubeDef } from "../../utils/qlikUtils";
import { absoluteRegulate } from "../../utils/utilFunctions";

import { useAbsenceComponentStyles } from "./AbsenseComponent.styles";

type AbsenceDisplayProps = {
  title: string;
  QAP_id: string;
  QAP_osuus_id: string;
};

const AbsenceDisplay = (props: AbsenceDisplayProps) => {
  const { title, QAP_osuus_id, QAP_id } = props;
  const { t } = useTranslation();

  const [qap, setQap] = useState("");
  const [qapOsuus, setQapOsuus] = useState("");
  const classes = useAbsenceComponentStyles();
  const { app, qlikAppLoading } = useQlikAppContext();

  useEffect(() => {
    if (app && !qlikAppLoading) {
      const hcd = createHyperCubeDef([QAP_id, QAP_osuus_id]);

      app.createCube(hcd, (hc) => {
        const matrix = hc.qHyperCube.qDataPages[0].qMatrix[0];
        setQap(matrix[0].qText);
        setQapOsuus(
          `${
           
            isNaN(matrix[1].qNum)
              ? matrix[1].qText.replace(',', '.')
              : (matrix[1].qNum * 100).toFixed(0)
          }%`
        );
      });
    }
    return () => {};
  }, [QAP_id, QAP_osuus_id, app, qlikAppLoading]);

  return (
    <Box className={classes.dataDisplay}>
      <Typography variant="body2">{title}</Typography>
      <Typography variant={"h5"}>{qap}</Typography> {/** Will be modified soon */}
      <Typography variant="body2">
        {t("WorkAbilityRiskPage.AbsenceTable.Person")}{" "}
        <span className={classes.percentText}>({qapOsuus})</span>
      </Typography>
    </Box>
  );
};

export default AbsenceDisplay;
