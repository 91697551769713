import { Box } from "@mui/system";
import TabComponent from "../../../components/TabComponent/TabComponent";
import { useWorkAbilityRiskPageStyles } from "../WorkAbilityRiskPage.styles";

const tabValues = [
  { title: "Ilmiöt", tabValue: 0 },
  { title: "Poissaolot", tabValue: 1 }
];

type PageTabsProps = {
  styleClass: string;
  currentTab: number;
  toggleTab: (tab: number) => void;
};

const PageTabs = (props: PageTabsProps) => {
  const classes = useWorkAbilityRiskPageStyles();
  const { currentTab, toggleTab, styleClass } = props;

  return (
    <Box className={`${classes.kpiContainer} ${styleClass}`}>
      {tabValues.map((tab) => (
        <TabComponent
          key={tab.title}
          title={tab.title}
          selected={currentTab === tab.tabValue}
          onClick={() => toggleTab(tab.tabValue)}
        />
      ))}
    </Box>
  );
};

export default PageTabs;
